import React, { Component } from "react";
import {
  Link,
} from "react-router-dom";

import Logout from '../../components/Logout';
import InlineLoading from '../../components/InlineLoading';
import MiniLoading from '../../components/MiniLoading';
import ProgressOverview from '../../components/ProgressOverview';

import authFetch from '../../services/authFetch';
import { Translate } from "react-localize-redux";

class ManagementSpecimen extends Component {
	constructor(props) {
    super(props);
    this.state = {
	  	keyword: "",
	  	recents:[],
      specimens: [],
      selected_type: ""
    };
    
    this.handleSearchInput = this.handleSearchInput.bind(this);
    this.handleTypeChange = this.handleTypeChange.bind(this);
    
    this.doSearch = this.doSearch.bind(this);
    this.captureBarcode = this.captureBarcode.bind(this);
  }
    
  componentDidMount() {
	  document.addEventListener("keydown", this.captureBarcode, false);
    authFetch.get('specimens', (result) => {
			//console.log(result.specimens)
			if (result.success) {
				this.setState({ specimens: result.specimens });
			}
		});
  }
  
  componentWillUnmount(){
    document.removeEventListener("keydown", this.captureBarcode, false);
  }
  
  handleSearchInput(e)
  {
	  var target = e.target;
	  this.setState({keyword:target.value});
  }

  handleTypeChange(e) {
	  var target = e.target;
	  this.setState({selected_type:target.value});
  }
  
  captureBarcode(e) 
  {
	  this.barcodeInput.focus(); 
	  return;
	  /*
		var secret = this.state.keyword; 
	  var code = e.keyCode || e.which;
  	if (code == '13') {
	  	this.doSearch(e);
    } else if (code == '27') {
	    secret = "";
	    this.setState({keyword:secret});
	  } else if(String.fromCharCode(code).match(/(\w|\s)/g)) {
		  e.preventDefault();
	    secret = secret+e.key;
	    this.setState({keyword:secret});
    }
    */
  }
  
  doSearch(e) {
	  e.preventDefault();
    if(!this.state.keyword) {
      return
    }
    let selected_type;
    if(!this.state.selected_type) {
      window.doAlert('กรุณาเลือกสิ่งส่งตรวจ');
      return
    } else {
      if(this.state.selected_type==='all')selected_type = null;
      else selected_type = this.state.selected_type;
    }
	  var data = {specimen:this.state.keyword,type:selected_type}
	  var recents = [].concat( this.state.recents );
	  var uniq = recents.length+1;
	  recents.unshift({id:this.state.keyword,status:'loading',uniq:uniq});
	  this.setState({recents:recents,keyword:""})
	  
	  authFetch.post('visit/specimens/one',data,(result)=>{
		  var status = "loading";
		  if(result.success){
        if(result.errors && result.errors.length>0) {
          document.getElementById('errorsound').play()
          status = "error";
        } else if(result.result==='duplicate') {
          //document.getElementById('warningsound').play()
          status = "duplicated";
        } else if(result.result==='wrong_type') {
          document.getElementById('errorsound').play()
            status = "wrong_type";
        } else {
          status = "success";
        }
	    } else {
		    status = "error";
	    }
	    
		  var recents = [].concat( this.state.recents );
			recents = recents.map((item,index)=>{
				if(item.uniq==uniq)
				{
					item.status=status;
					if(result.specimen){
						item = {...item,...result.specimen}
					}
				}
				return item;
			});
		  this.setState({recents:recents})
	  });
	}

  render() {
    return (
			<Translate>
			{({ translate }) => 
	      <div className="management-page page">
	      	<header className="page-header">
		      	<Link className="link-page-back" to={'/management'}>
	      			{translate("back")}
	      		</Link>
		      	<h1>{translate("count_specimen")}</h1>
		      	<Logout/>
	      	</header>
	      	
	      	<main className="page-content">
		      	<div className="page-content-main">
                <audio id="warningsound" src={require('assets/images/sound_warning.mp3')} />
                <audio id="errorsound" src={require('assets/images/sound_error.mp3')} />
                <div className="small-action-box">
                  <div className="small-action-box-image">
                    {translate("scan_barcode_to_add")}
                  </div>
                  <div className="small-action-box-content">
                    <form onSubmit={this.doSearch} className="barcode-form">
                      <input type="text" 
                        className="barcode-input" placeholder={translate("vn")} 
                        value={this.state.keyword} onChange={this.handleSearchInput} 
                        ref={(input) => { this.barcodeInput = input; }} autoFocus />
                      <select name="type" value={this.state.selected_type} onChange={this.handleTypeChange} className="barcode-select">
                        <option value="">{translate('please_select_type')}</option>
                        <option value="all">{translate('all_types')}</option>
                        {this.state.specimens.map((item, index) => {
                          return <option key={index} value={item.id}>{item.name_th?item.name_th:item.name}</option>
                        })}
                      </select>
                      <button type="submit" className="barcode-form-btn"><img src={require('assets/images/icon-barcode.svg')} width="45" /></button>
                    </form>
                  </div> 
                </div> 			
	      			<div className="specimen-batch">
	      				<div className="specimen-batch-content">
		      				{this.state.recents && this.state.recents.length>0?(
			      				<table  className="light-table specimen-batch-count specimen-count-table">
				      				<thead>
					      				<tr>
						      				<th style={{width:'20%'}}  className="text-left">#</th>
						      				<th style={{width:'25%'}}   className="text-left">{translate("name")}</th>
						      				<th style={{width:'20%'}}  className="text-center">{translate("vn")}</th>
						      				<th style={{width:'20%'}}  className="text-center">{translate("specimen")}</th>
						      				<th style={{width:'10%'}}  className="text-center">{translate("time")}</th>
						      				<th style={{width:'10%'}}  className="text-center">{translate("status")}</th>
						      				<th style={{width:'5%'}}  className="text-center">&nbsp;</th>
						      			</tr>
					      			</thead>
					      			<tbody>
						      			{this.state.recents.map(function(item, index){
													return (
							      			<tr key={index} className={item.status}>
								      			<td style={{width:'20%'}}  >{item.id}</td>
								      			<td style={{width:'25%'}}  >{item.fullname}</td>
								      			<td style={{width:'20%'}}   className="text-center">{item.vn}</td>
								      			<td style={{width:'20%'}}   className="text-center"><small>{item.specimen_type}</small></td>
								      			<td style={{width:'10%'}}   className="text-center"><small>{item.counted_at}</small></td>
								      			<td style={{width:'10%'}}   className="text-center"><small>{translate(item.status)}</small></td>
								      			<td style={{width:'5%'}}   className="text-center">
									      			{(() => {
												        switch (item.status) {
												          case "loading":   return <MiniLoading show={true}/>;break;
												          case "duplicated":return <i className="far fa-clone"></i>;break;
												          case "success":  	return <i className="fas fa-check"></i>;break;
												          case "wrong_type":return <i className="fas fa-exclamation-circle"></i>;break;
												          case "error":  	  return <i className="fas fa-exclamation-circle"></i>;break;
												          default:      		return <i className="fas fa-exclamation-circle"></i>;break;
												        }
												      })()}
								      			</td>
								      		</tr>
								      		)
								      	})}
						      		</tbody>
			      				</table>
			      			):(
			      				<div className="waiting-for-scan">
				      				{translate("scan_barcode_to_count_specimen")}
			      				</div>
			      			)}
		      				
	      				</div>
	    				</div>
	      			
		      	</div>
		      	<aside className="page-content-aside"> 
		      		<div className="page-content-aside-header">
			      		<div className="page-content-aside-header-title">{translate("overview")}</div>
			      		<div className="page-content-aside-header-button">
			      			<Link to={'/management/specimens_all'}>
				      			<img src={require('assets/images/icon-expand.svg')} width="30" />
			      			</Link>
			      		</div>
			      	</div>
			      	
		      		<div className="page-content-aside-content">
      					<ProgressOverview  history={this.props.history}/>
		      		</div>
		      	</aside>
		      </main>
	      </div>
      }
			</Translate>
    );
  }
}

export default ManagementSpecimen;
