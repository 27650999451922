import React, { Component } from "react";
import {
	Link,
} from "react-router-dom";

import Logout from '../../components/Logout';
import InlineLoading from '../../components/InlineLoading';
import MiniLoading from '../../components/MiniLoading';

import authFetch from '../../services/authFetch';

import PatientSearch from '../../components/PatientSearch';
import { Translate } from "react-localize-redux";

class ManagementOtherSearch extends Component {
	constructor(props) {
		super(props);
		this.state = {
			recents: null,
			visit: null,
			show_form: false,
			loading: false,
			keyword: "",
			error: null,
			extraSearch:false
		};

		this.doSearch = this.doSearch.bind(this);
		this.updateRecent = this.updateRecent.bind(this);
		this.handleKeywordChange = this.handleKeywordChange.bind(this);
		this.searchKeyword = this.searchKeyword.bind(this);

		this.captureBarcode = this.captureBarcode.bind(this);

		this.cancelExtraSearch = this.cancelExtraSearch.bind(this);
		this.extraSearchSelect = this.extraSearchSelect.bind(this);
	}

	componentDidMount() {
		document.addEventListener("keydown", this.captureBarcode, false);
		this.updateRecent();
	}

	componentWillUnmount() {
		document.removeEventListener("keydown", this.captureBarcode, false);
	}

	captureBarcode(e) {
		this.barcodeInput.focus();
		return;
	}

	updateRecent() {
		authFetch.get('other/recent', (result) => {
			if (result.success && result.visits) {
				this.setState({ recents: result.visits });
			} else {
				this.setState({ recents: [] });
			}
		});
	}

	handleKeywordChange(event) {
		var target = event.target;
		this.setState({ keyword: target.value });
	}

	searchKeyword(keyword) {
		this.setState({ keyword: keyword }, () => this.doSearch());
	}

	doSearch(e) {
		if (e) e.preventDefault();
		this.setState({ loading: true });

		authFetch.get('other/search?keyword=' + this.state.keyword, (result) => {
			if (result.success && result.visit) {
				this.choosePatient(result.visit.vn)
			} else {
				this.doExtraSearch()
			}
			this.setState({ loading: false });
		});
	}

	choosePatient(vn) {
		this.props.history.push('/management/other_list/' + vn)
	}

	doExtraSearch() {
		this.setState({extraSearch:true})
	}

	extraSearchSelect(vn) {
		this.choosePatient(vn)
		this.cancelExtraSearch()
	}

	cancelExtraSearch(){
		this.setState({extraSearch:false})
	}

	render() {
		return (
			<Translate>
				{({ translate }) =>
				<div>
					{this.state.extraSearch?
						<PatientSearch back={this.cancelExtraSearch} select={this.extraSearchSelect} keyword={this.state.keyword} />
					:
					<div className="management-page page">
						<header className="page-header">
							<Link className="link-page-back" to={'/home'}>
								{translate("back")}
							</Link>
							<h1>{translate("other_result")}</h1>
							<Logout />
						</header>

						<main className="page-content">
							<div className="page-content-main">
								<div className="page-content-padded">
									<div className="action-box">
										<div className="action-box-image">
											<img alt="" src={require('assets/images/icon-search.svg')} />
										</div>
										<div className="action-box-content">
											<p><strong>{translate("search_patient")}</strong></p>
											<form onSubmit={(e) => this.doSearch(e)} className="search-form">
												<input type="text"
													className="form-control" placeholder={translate("search")}
													value={this.state.keyword} onChange={this.handleKeywordChange}
													ref={(input) => { this.barcodeInput = input; }} autoFocus />
												{this.state.loading === true ? (
													<button type="button" className="search-form-btn"><MiniLoading color="#333333" show={true} /></button>
												) : (
													<button type="submit" className="search-form-btn"><i className="fa fa-search"></i></button>
												)}
											</form>
										</div>
									</div>
								</div>
							</div>
							<aside className="page-content-aside">
								<div className="page-content-aside-header">
									<div className="page-content-aside-header-title">{translate("latest_data_added")}</div>
									<div className="page-content-aside-header-button">
										{/*
			      			<Link to={'/register/pe_previous'}>
				      			<img src={require('assets/images/icon-expand.svg')} width="30" />
			      			</Link>
			      			*/}
									</div>
								</div>

								<div className="page-content-aside-content">
									{this.state.recents !== null ? (
										<table className="page-content-aside-table">
											<thead>
												<tr>
													<th className="text-left">{translate("name")}</th>
													<th className="text-center" width="80">{translate("vn")}</th>
													<th className="text-center" width="30"></th>
												</tr>
											</thead>
											<tbody>
												{this.state.recents.map((item, index) => {
													return (<tr key={index}>
														<td className="text-left"><strong>{item.fullname}</strong><br/><small>{item.order_name}</small></td>
														<td className="text-center">{item.vn}</td>
														<td><i className="fa fa-edit" onClick={() => { this.props.history.push('/management/other_form/' + item.vn+"/"+item.order_id) }}></i></td>
													</tr>)
												})}
											</tbody>
										</table>
									) : (
										<InlineLoading show={true} />
									)}
								</div>
							</aside>
						</main>
					</div>
					}
				</div>
				}
			</Translate>
		);
	}
}

export default ManagementOtherSearch;
