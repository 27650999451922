import React from "react"
import { RadioBox } from "../helper";
import { Translate } from "react-localize-redux";

export class ExpOc extends RadioBox {
	constructor(props) {
		super(props, props.setState)
	}
	render() {
		return (
			<Translate key={this.props.template.id}>
				{({ translate }) => 
					<div className="oc-form-group-wrapper">
						<h6>{ this.props.template.name }</h6>
                        <ul>
                            <li>
                                <label
                                    className={`
                                        oc-form-label
                                        ${this.state.value === 'ใช่' ? 'active': ''}
                                    `.trim()}
                                >
                                    {translate('yes')}
                                    <input
                                        name={`${this.props.template.id}`}
                                        type="radio"
                                        value="ใช่"
                                        checked={this.state.value === 'ใช่'}
                                        onChange={(e) => this.onChangeHandler(e)}
                                    />
                                </label>
                            </li>
                            <li>
                                <label
                                    className={`
                                        oc-form-label
                                        ${this.state.value === 'ไม่' ? 'active': ''}
                                    `.trim()}
                                >
                                    {translate('no')}
                                    <input
                                        name={`${this.props.template.id}`}
                                        type="radio"
                                        value="ไม่"
                                        checked={this.state.value === 'ไม่'}
                                        onChange={(e) => this.onChangeHandler(e)}
                                    />
                                </label>
                            </li>
                        </ul>
					</div>
				}
			</Translate>
		)
	}
}