import React, { Component } from "react";
import { Translate } from "react-localize-redux";

class ViewPDF extends Component {

	constructor(props) {
		super(props);

		this.state = {
			show_modal: false
		};

		this.showModel = this.showModel.bind(this);
		this.hideModel = this.hideModel.bind(this);
	}

	componentDidMount() {
		
	}

	showModel() {
		this.setState({ show_modal: true })
	}

	hideModel() {
		this.setState({ show_modal: false  })
	}
	
	render() {
		return (
			<Translate>
				{({ translate }) =>
					<div>
						{this.state.show_modal===false ? 
							<button className="btn-secondary btn-round-sm" onClick={this.showModel}>ดูไฟล์ <small><i className="fas fa-file-pdf"></i></small></button>
						:
							<div className="modal modal-lg">
								<div className="modal-body">
									<div className="modal-header">
										ViewPDF
										<i onClick={this.hideModel}>×</i>
									</div>
									<div className="modal-content">
										{this.props.file?(
											<embed src={this.props.file} className="embed-pdf" width="100%" height="375"  type="application/pdf"/>
										):null}
									</div>
								</div>
							</div>
						}
					</div>
				}
			</Translate>
		);
	}
}

export default ViewPDF;