import React from "react"
import { RadioBox } from "./helper";
import { Translate } from "react-localize-redux";

const ans = ['T', 'R', 'R', 'L', 'T', 'B', 'L', 'R', 'L', 'B', 'R', 'B', 'T', 'R']

export class BothEyes extends RadioBox {
    constructor(props) {
        super(props, props.setState)
    }
	render() {
		return (
			<Translate key={this.props.template.id}>
                {({ translate }) => 
                    <tr>
                        <td colSpan="2">
                            2. {this.props.template.name}
                        </td>
                        {
                            ans.map((x, index) => 
                                <td
                                    key={index}
                                    className={`
                                        ${index+1 < (this.props.limit.lower_limit??7) ? "highlight": ''}
                                        ${index+1 === 10 ? "border-left": ''}
                                    `.trim()}
                                >
                                    <label className={this.state.value === `${index+1}` ? 'active': ''}>
                                        <input
                                            name="both-eye"
                                            type="radio"
                                            value={`${index+1}`}
                                            onChange={(e) => this.onChangeHandler(e)}
                                            onClick={e => this.onClickHandler(e)}
									        checked={this.state.value === `${index+1}`}
                                        />
                                        {x}
                                    </label>
                                </td>
                            )
                        }
                        <td></td>
                    </tr>
				}
			</Translate>
		)
	}
}
