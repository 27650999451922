import React, { Component } from "react";

class MainLogo extends Component {
	constructor(props) {
    super(props);
    this.state = {};
  }
  
  render() {
    return (
      <div className="main-logo">
	      <img  alt="CHECKMATE" src={require('assets/images/logo.svg')} width="300" />
      </div>
    );
  }
}

export default MainLogo;
