import React from "react"
import { RadioBox } from "./helper";
import { Translate } from "react-localize-redux";

const ans = [
    {top: '20', bottom: '200'},
    {top: '20', bottom: '100'},
    {top: '20', bottom: '70'},
    {top: '20', bottom: '50'},
    {top: '20', bottom: '40'},
    {top: '20', bottom: '35'},
    {top: '20', bottom: '30'},
    {top: '20', bottom: '25'},
    {top: '20', bottom: '22'},
    {top: '20', bottom: '20'},
    {top: '20', bottom: '18'},
    {top: '20', bottom: '17'},
    {top: '20', bottom: '15'},
    {top: '20', bottom: '13'},
]
export class SnellenVision extends RadioBox {
    constructor(props) {
        super(props, props.setState)
    }
	render() {
		return (
			<Translate key='snellen'>
				{({ translate }) => 
					<tr>
						<td colSpan="2" className="oc-form-table-section-header">
							Snellen
						</td>
                        {
                            ans.map((x, index) => 
                                <td key={index} className="oc-form-table-section-header">
                                    <label>
                                        <input
                                            name="snellen-vision"
                                            type="radio"
                                        />
                                        {x.top}<br />
                                        {x.bottom}
                                    </label>
                                </td>
                            )
                        }
                        <td className="oc-form-table-section-header"></td>
					</tr>
				}
			</Translate>
		)
	}
}