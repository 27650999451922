import React, { Component } from "react";
import {
  Link,
} from "react-router-dom";

class SwitchUnit extends Component {
	constructor(props) {
    super(props);
  }
  
  
  render() { 
	  
		return (
			<Link to={'/switch_unit'}><i className="fa fa-building"></i></Link>
		);
  }
}

export default SwitchUnit;