import React, { Component } from "react";

import { Translate } from "react-localize-redux";

class SortArrow extends Component {
	constructor(props) {
    super(props);
    this.state = {
    }
  }
  
  render() {
	  return (
	  <Translate>
			{({ translate }) => 
				<u className="table-sort-arrow"><i className={"fa fa-sort-"+(this.props.desc?"up":"down")}></i></u>
    	}
		</Translate>
		)
  }
}

export default SortArrow;