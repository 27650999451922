import React, { Component } from "react";
import { Translate } from "react-localize-redux";
import Loading from '../components/Loading';
import MiniLoading from '../components/MiniLoading';
import moment from 'moment';
import nativeReader from '../helpers/NativeReadCard.js';

class CardReader extends Component {
	constructor(props) {
		super(props);

		this.state = {
			show_modal: false,
			has_reader: false,
			reading: false,
			loading: false
		};

		this.updateData = this.updateData.bind(this);
		this.readCard = this.readCard.bind(this);
    this.readingCard = this.readingCard.bind(this);
    this.changeReading = this.changeReading.bind(this);
    this.changeHasReader = this.changeHasReader.bind(this);
    
		this.showModel = this.showModel.bind(this);
		this.hideModel = this.hideModel.bind(this);

		this.reader = null;
	}

	componentDidMount() {
    setTimeout(()=>{
      this.readCard();
      this.setState({'has_reader' : window.cardreader.has_reader})
    },300);
	}

	componentWillUnmount() {
    window.cardreader.removeListener('read', this.readingCard);
    window.cardreader.removeListener('reading', this.changeReading);
    window.cardreader.removeListener('has_reader', this.changeHasReader);
  }
  
  changeReading(status) {
    console.log('Reading',status)
		this.setState({ reading: status,'has_reader' : window.cardreader.has_reader });
  }

  changeHasReader(status) {
    console.log('Has Reader',status)
		this.setState({ has_reader: status });
  }

	showModel() {
		this.setState({ 'show_modal': true });
		this.readCard();
	}

	hideModel() {
		this.setState({ 'show_modal': false });
	}

	updateData(data) {
		this.props.update(data);
		this.hideModel();
	}

	reset = () => {
    window.cardreader.readNow();
	}

	readCard() {
    window.cardreader.on('read', this.readingCard);
    window.cardreader.on('reading', this.changeReading);
    window.cardreader.on('has_reader', this.changeHasReader);
  }
  
  readingCard(data) {
    console.log('Done reading')
    console.log(data)
    switch (data.gender) {
      case "1":
        data.gender = "male";
        break;
      case "2":
        data.gender = "female";
        break;
      default:
        data.gender = "unknown";
        break;
    }
    data.dob = moment(data.dob).subtract(543, 'years').format('Y-MM-DD');
    this.updateData(data);
  }

	render() {
		return (
			<Translate>
				{({ translate }) =>
					<div className="card-reader">
            {this.state.reading?
              <div>
                กำลังอ่านบัตรประชาชน... <i className="fas fa-id-card"></i> <div className="display-inline">
                <MiniLoading width="30" height="30" show="1"/></div>
              </div>
            :<div>
              {this.state.has_reader?
                <p>
                  {!this.props.hidetext?
                    <span>{translate("please_insert_id_card")} <i className="fas fa-id-card"></i></span>
                  :null}
                  &nbsp;&nbsp;
                    <button className="btn btn-round-sm" onClick={this.reset}>
                      {translate("read_card")} <i className="fas fa-sync-alt"></i>
                    </button>
                </p>
              : <p>ไม่พบเครื่องอ่านบัตรประชาชน</p> }
            </div>
            
            }
					</div>
				}
			</Translate>
		);
	}
}

export default CardReader;