import React, { Component } from "react";
import {
  Link,
} from "react-router-dom";

class MiniSetting extends Component {
	constructor(props) {
    super(props);
  }
  
  
  render() { 
	  
		return (
			<Link to={'/setting'}><i className="fa fa-cog"></i></Link>
		);
  }
}

export default MiniSetting;