import React from "react"
import { RadioBox } from "./helper";
import { Translate } from "react-localize-redux";

const ans = [
    {top: 'A', bottom: '12'},
    {top: 'B', bottom: '5'},
    {top: 'C', bottom: '26'},
    {top: 'D', bottom: '6'},
    {top: 'E', bottom: '16'},
    {top: 'F', bottom: '0'},
]
export class ColorVision extends RadioBox {
    constructor(props) {
        super(props, props.setState)
    }
	render() {
		return (
			<Translate key={this.props.template.id}>
				{({ translate }) => 
					<tr>
						<td colSpan="2">
							6. {this.props.template.name}
						</td>
                        {
                            ans.map((x, index) => 
                                <td key={index} colSpan="2" className="oc-form-table-section-header">
                                    <label>
                                        {x.top}<br />
                                        {x.bottom}
                                    </label>
                                </td>
                            )
                        }
                        <td>
                            <label
                                className={this.state.value === `normal` ? 'active': ''}
                            >
                                <input
                                    value="normal"
                                    type="radio"
                                    onChange={(e) => this.onChangeHandler(e)}
                                    onClick={e => this.onClickHandler(e)}
                                    checked={this.state.value === 'normal'}
                                />
                                {translate('normal')}
                            </label>
                            <label
                                className={this.state.value === 'abnormal' ? 'active': ''}
                            >
                                <input
                                    value="abnormal"
                                    type="radio"
                                    onChange={(e) => this.onChangeHandler(e)}
                                    onClick={e => this.onClickHandler(e)}
                                    checked={this.state.value === 'abnormal'}
                                />
                                {translate('abnormal')}
                            </label>
                        </td>
                        <td
                            colSpan="2"
                            style={{
                                width: 100
                            }}
                        >
                            <input
                                type="text"
                                style={{
                                    width: '100%',
                                    display: this.state.value === 'abnormal' ? 'block': 'none'
                                }}
                                value={this.state.note}
                                onChange={(e) => this.onChangeHandler(e)}
                            />
                        </td>
					</tr>
				}
			</Translate>
		)
	}
}