import React, { Component } from "react";
import {
	Link,
} from "react-router-dom";
import authFetch from '../../services/authFetch';

import MainLogo from '../../components/MainLogo';
import Logout from '../../components/Logout';

import Auth from '../../helpers/Auth';
import { Translate } from "react-localize-redux";

class Setting extends Component {
	constructor(props) {
		super(props);
		this.state = {
			units: [],
			unit: {id:null,name:null},
			loading: true
		};
		
		this.handleUnitChange = this.handleUnitChange.bind(this);
		this.saveSetting = this.saveSetting.bind(this);
	}

	componentDidMount() {
		authFetch.get('units', (data) => {
			if (data.success) {
				this.setState({ 'loading': false, 'units': data.units });
			} else {
				this.setState({ 'loading': false })
			}
		});
	}

	handleUnitChange(event) {
		const target = event.target;

		this.setState({
			unit: {id:target.value, name:target.options[target.selectedIndex].text}
		});
	}

	saveSetting() {
		Auth.switch_unit(this.state.unit)
		//   alert("Saved");
		this.props.history.push('/home');
	}

	render() {
		if(this.state.units && this.state.units.length>0) {
			var unitOption = this.state.units.map(function (unit, index) {
				return <option value={unit.id} key={index}>{unit.job_name} {unit.location}</option>;
			});
		}

		return (
			<Translate>
				{({ translate }) =>
					<div className="setting-page">
						<MainLogo />
						<Logout />
						<div className="page-content-padded">
							<h1>{translate("switch_unit")}</h1>

							<table className="light-table">
								<tbody>
									<tr>
										<th width="200" className="text-left">{translate("switch_unit")}</th>
										<td>
											<select className="form-control" name="unit_id"
												value={this.state.unit_id} onChange={this.handleUnitChange} required>
												<option value="">{translate("unit")}</option>
												{unitOption}
											</select>
										</td>
									</tr>
								</tbody>
							</table>
							<br />
							<p className="text-right"><button className="btn btn-round" onClick={this.saveSetting}>เปลี่ยน</button></p>
						</div>
					</div>
				}
			</Translate>
		);
	}
}

export default Setting;
