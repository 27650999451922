import React from "react"
import { RadioBox } from "./helper";
import { Translate } from "react-localize-redux";

export class BinocVision extends RadioBox {

	constructor(props) {
		super(props, props.setState)
	}

	render() {
		return (
			<Translate key={this.props.template.id}>
				{({ translate }) => 
					<tr>
						<td colSpan="2">
							1. {this.props.template.name}
						</td>
						<td colSpan="6">
							<label className={this.state.value === '4' ? 'active': ''}>
								<input
									name="binocular-vision"
									type="radio"
									value="4"
									onChange={(e) => this.onChangeHandler(e)}
									onClick={e => this.onClickHandler(e)}
									checked={this.state.value === '4'}
								/>
								{/* <i className="far fa-check-circle"></i> */}
								{ translate("form_vision_binocular_4_cubes") }
							</label>
						</td>
						<td colSpan="6">
							<label className={this.state.value === '2' ? 'active': ''}>
								<input
									name="binocular-vision"
									type="radio"
									value="2"
									onChange={(e) => this.onChangeHandler(e)}
									onClick={e => this.onClickHandler(e)}
									checked={this.state.value === '2'}
								/>
								{/* <i className="far fa-circle"></i> */}
								{ translate("form_vision_binocular_2_cubes") }
							</label>
						</td>
						<td colSpan="6">
							<label  className={this.state.value === '3' ? 'active': ''}>
								<input
									name="binocular-vision"
									type="radio"
									value="3"
									onChange={(e) => this.onChangeHandler(e)}
									onClick={e => this.onClickHandler(e)}
									checked={this.state.value === '3'}
								/>
								{ translate("form_vision_binocular_3_cubes") }
							</label>
						</td>
					</tr>
				}
			</Translate>
		)
	}
}