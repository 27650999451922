import React, { Component } from "react";
import { Translate } from "react-localize-redux";

import authFetch from '../services/authFetch';
class ProgramSelect extends Component {
	constructor(props) {
		super(props);

		this.state = {
			show_modal: false,
			data: {}
		};

		this.doConfirm = this.doConfirm.bind(this);
		this.doDeny = this.doDeny.bind(this);
		this.doneMerge = this.doneMerge.bind(this);
	}

	componentDidMount() {
		// console.log(this.props)
	}

	doConfirm() {
		authFetch.post('visit-link-confirm/' + this.props.vn, null, (result) => {
			this.doneMerge();
		});
	}

	doDeny() {
		this.doneMerge();
	}

	doneMerge() {
		this.props.finished();
	}

	render() {
		return (
			<Translate>
				{({ translate }) =>
					<div className="confirm-merge-data">
						{this.props.suspect ? (
							<div className="modal">
							<div className="modal-body">
								<div className="modal-header">{translate("confirm_data_binding")}</div>
								<div className="modal-content">
									<p>{translate("previous_checkup_date")}:<br/><strong>{this.props.suspect.registered_at}</strong></p>
									<hr/>
									{this.props.suspect.patient?
									<p className="text-left">
										{translate("name")}: <strong>{this.props.suspect.patient.prefix} {this.props.suspect.patient.firstname} {this.props.suspect.patient.lastname}</strong><br/>
										{translate("company_branch")}: {this.props.suspect.patient.company_name}<br/>
										{translate("thai_citizen_id")}: {this.props.suspect.patient.cid}<br/>
										{translate("employee_id")}: {this.props.suspect.patient.eid}<br/>
										{translate("birthday")}: {this.props.suspect.patient.dob}<br/>
										{translate("phone_number")}: {this.props.suspect.patient.tel}<br/>
									</p>
									:(null)}
									<p>
									   <button className="btn btn-block btn-round-sm" onClick={this.doDeny}>{translate("not_same_person")} <i className="fa fa-times"></i></button>
								   </p>
									 <p>
										<button className="btn btn-primary btn-block btn-round" onClick={this.doConfirm}>{translate("confirm_same_person")} <i className="fa fa-check"></i></button>
									</p>
								</div>
							</div>
						</div>
						) : (null)}
					</div>
				}
			</Translate>
		);
	}
}

export default ProgramSelect;