import React from "react"
import { CheckBox } from "./helper";
import { Translate } from "react-localize-redux";

export class RHX extends CheckBox {
	constructor(props) {
		super(props, props.setState)
	}
	componentDidMount() {
		this.setState({
			value: this.props.template.value.split(',')
		})
	}
	render() {
		return (
			<Translate key={this.props.template.id}>
				{({ translate }) => 
					<div className="oc-form-group-wrapper checkbox">
						<h6>{this.props.template.name}</h6>
						<ul>
							<li>
								<label
									className={`
										oc-form-label
										${this.state.value.includes('สั้น') ? 'active': ''}
									`.trim()}
								>
									<input
										name="rhx-1"
										type="checkbox"
										value="สั้น"
										checked={this.state.value.includes('สั้น')}
										onChange={(e) => this.onChangeHandler(e)}
									/>
									{ translate("form_vision_problem_myopia") }
								</label>
							</li>
							<li>
								<label
									className={`
										oc-form-label
										${this.state.value.includes('ยาว') ? 'active': ''}
									`.trim()}
								>
									<input
										name="rhx-2"
										type="checkbox"
										value="ยาว"
										checked={this.state.value.includes('ยาว')}
										onChange={(e) => this.onChangeHandler(e)}
									/>
									{ translate("form_vision_problem_long_sighted") }
								</label>
							</li>
							<li>
								<label
									className={`
										oc-form-label
										${this.state.value.includes('เอียง') ? 'active': ''}
									`.trim()}
								>
									<input
										name="rhx-3"
										type="checkbox"
										value="เอียง"
										checked={this.state.value.includes('เอียง')}
										onChange={(e) => this.onChangeHandler(e)}
									/>
									{ translate("form_vision_problem_astigmatism") }
								</label>
							</li>
						</ul>
					</div>
				}
			</Translate>
		)
	}
}