import React from "react"
import { TextBox } from "../helper";
import { Translate } from "react-localize-redux";

export class EXPY extends TextBox {
	constructor(props) {
		super(props, props.setState)
	}
	render() {
		return (
			<Translate key={this.props.template.id}>
				{({ translate }) => 
					<div className="oc-form-group-wrapper">
						<h6>
							<label>
								{ this.props.template.name }
								<input
									type="text"
									value={this.state.value}
									onChange={(e) => this.onChangeHandler(e)}
								/>
							</label>
						</h6>
					</div>
				}
			</Translate>
		)
	}
}