import React, { Component } from "react";

import PatientSearch from '../../components/PatientSearch';

import { Translate } from "react-localize-redux";

class ManagementPatientSearch extends Component {
	constructor(props) {
    super(props);
    this.state = {};
    this.searchSelect = this.searchSelect.bind(this);
  }
	
	searchSelect(vn)
	{
		this.props.history.push('/management/patient/'+vn)
	}

  render() {
    return (
			<Translate>
			{({ translate }) => 
				<PatientSearch back={()=>this.props.history.push('/management')} select={this.searchSelect} keyword="" />
      }
			</Translate>
    );
  }
}

export default ManagementPatientSearch;
