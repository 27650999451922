let pcsclite;
let isNative = false;
if (typeof (window.pcsclite) !== "undefined") {
  isNative = true;
  pcsclite = window.pcsclite; // eslint-disable-line no-undef
}

// command list
const _INIT_SELECT = [0x00, 0xA4, 0x04, 0x00, 0x08, 0xA0, 0x00, 0x00, 0x00, 0x54, 0x48, 0x00, 0x01]

const _SELECT1 = [0x00, 0xC0, 0x00, 0x00]
const _SELECT2 = [0x00, 0xc0, 0x00, 0x01]

let _SELECT = _SELECT1;

const _CID = [0x80, 0xb0, 0x00, 0x04, 0x02, 0x00, 0x0d]
const _THFULLNAME = [0x80, 0xb0, 0x00, 0x11, 0x02, 0x00, 0x64]
const _ENFULLNAME = [0x80, 0xb0, 0x00, 0x75, 0x02, 0x00, 0x64]
const _BIRTH = [0x80, 0xb0, 0x00, 0xD9, 0x02, 0x00, 0x08]
const _GENDER = [0x80, 0xb0, 0x00, 0xE1, 0x02, 0x00, 0x01]
const _ISSUER = [0x80, 0xb0, 0x00, 0xF6, 0x02, 0x00, 0x64]
const _ISSUE = [0x80, 0xb0, 0x01, 0x67, 0x02, 0x00, 0x08]
const _EXPIRE = [0x80, 0xb0, 0x01, 0x6F, 0x02, 0x00, 0x08]
const _ADDRESS = [0x80, 0xb0, 0x15, 0x79, 0x02, 0x00, 0x64]

class ThaiIDReader {
  reader = null;
  pcsc = null;
  protocol = null;
  reading = false;

  constructor() {
    this.read = this.read.bind(this)
    this.close = this.close.bind(this)
    this.sendCommand = this.sendCommand.bind(this)
    this.transmit = this.transmit.bind(this)
    this.readerExit = this.readerExit.bind(this)
    this.pcscExit = this.pcscExit.bind(this)
  }

  async read(cb, errorcb) {
    if(this.reading || this.pcsc || this.reader)return;
    this.cb = cb;
    this.errorcb = errorcb;
    this.pcsc = pcsclite()
    this.pcsc.on('reader', (reader) => {
      //console.log(reader)
      if(reader.name.startsWith('Microsoft')) {
        console.log('ms exiting')
        return;
      }
      this.reader = reader;
      //console.log('onReader')
      reader.on('status', (status) => {
        //console.log(status)
        var changes = reader.state ^ status.state;
        if (changes) {
          if ((changes & reader.SCARD_STATE_EMPTY) && (status.state & reader.SCARD_STATE_EMPTY)) {
            console.log('Card removed')
            reader.disconnect(reader.SCARD_LEAVE_CARD, function(err) {
              if (err) {
                  console.log(err);
              } else {
                  console.log('Disconnected');
              }
            });
          } else if ((changes & reader.SCARD_STATE_PRESENT) && (status.state & reader.SCARD_STATE_PRESENT)) {
            // detect corrupt card and change select apdu
            console.log("Card inserted")
            if (status.atr[0] === 0x3B && status.atr[1] === 0x67) { 
              _SELECT = _SELECT2; 
              console.log('use select2')
            }

            setTimeout(()=>{
              reader.connect({ share_mode : reader.SCARD_SHARE_SHARED }, (err, protocol) => {
                if (err || !protocol) {
                  console.log(err)
                  this.close();
                  setTimeout(()=>{
                    this.read(this.cb,this.errorcb);
                  },600);
                  
                } else {
                  setTimeout(()=>{
                    this.readData(reader,protocol);
                  },600);
                }
              })
            },600);
          }
        }
      });

      this.reader.on('end', () => {
        console.log('Reader',  reader.name, 'removed');
        /*
        setTimeout(()=>{
          this.read(cb,errorcb)
        },1000);
        */
      });
    })
    this.pcsc.on('error', (err) => {
      console.log(err) 
    })
  }

  async readData(reader,protocol) {
    let result = {};
    //console.log("readData");
    try {
      await this.sendCommand(_INIT_SELECT,false,reader,protocol);
      result.cid = await this.sendCommand(_CID, true,reader,protocol);
      result.fullname = await this.sendCommand(_THFULLNAME, true,reader,protocol);
      result.enfullname = await this.sendCommand(_ENFULLNAME, true,reader,protocol);
      result.dob = await this.sendCommand(_BIRTH, true,reader,protocol);
      result.gender = await this.sendCommand(_GENDER, true,reader,protocol);
      result.address = await this.sendCommand(_ADDRESS, true,reader,protocol);
    } catch (e) {
      console.log(e)
    }
    console.log(result)
    this.cb(result);
    //console.log(result)
    //console.log('done reading');

    //pcsc.close();
    /*
    reader.disconnect(reader.SCARD_LEAVE_CARD, function(err) {
      if (err) {
          console.log(err);
      } else {
          console.log('Disconnected after read');
          //reader.close();
          //pcsc.close();
      }
    });
    */
  }

  async sendCommand(command, select,reader,protocol) {
    let data = null
    await this.transmit(command,reader,protocol)
    if (select) {
      data = await this.transmit(_SELECT.concat(command.slice(-1)),reader,protocol);
    }
    if(data) {
      return this.hex2string(data.toString('hex'));
    } else {
      return null;
    }
  }

  async transmit(command,reader,protocol) {
    return new Promise((resolve, reject) => {
      //console.log(this.protocol)
      reader.transmit(Buffer.from(command), 256, protocol, (err, data) => {
        if (err) {
          reject(err)
        }
        else {
          resolve(data)
        }
      })
    })
  }

  hex2string(input) {
    let tempHex = input
    if (tempHex.length > 4) tempHex = tempHex.slice(0, -4)
    const patt = /^[a-zA-Z0-9&@.$%\-,():`# \/]+$/
    const hex = tempHex.toString()
    let str = ''
    let tmp = ''
    for (let i = 0; i < hex.length; i += 2) {
      tmp = String.fromCharCode(parseInt(hex.substr(i, 2), 16))
      if (!tmp.match(patt)) {
        tmp = String.fromCharCode(parseInt(hex.substr(i, 2), 16) + 3424)
      }
      str += tmp
    }
    //str = str.replace(/#/g, ' ').trim()
    str = str.trim()
    return str
  }

  onPcscError(err) {
    console.log(err)

    if(err && this.startsWith(err,"Error: SCardGetStatusChange")) {
      /*
      this.readerExit();
      this.pcscExit();
      */
      //this.errorcb(err)
      /*
      setTimeout(()=>{
        this.con();
      },3000);
      */
    }
  }

  startsWith(str,searchString) {
    str = str+""
		return str.indexOf(searchString, 0) === 0;
  }


  close() {
    //console.log('closing reader');
    this.readerExit();
    this.pcscExit();
  }

  readerExit() {
    if (this.reader) {
      this.reader.close();
      this.reader = null;
      //console.log('readerexit')
      //this.pcscExit();
    }
  }

  pcscExit() {
    if (this.pcsc) {
      this.pcsc.close();
      this.pcsc = null;
      console.log('pcscExit')
    }
  }
}

class nativeReader {
  reader = null;

  constructor() {
    if (isNative) {
      this.reader = new ThaiIDReader(); // eslint-disable-line no-undef
    }
  }

  read(cb, errorcb, alwaycb) {
    if (isNative) {
      this.reader.read(cb, errorcb, alwaycb); // eslint-disable-line no-undef
    } else {
      errorcb("Cannot connect to card reader");
    }
  }

  close() {
    if (isNative) {
      this.reader.close(); // eslint-disable-line no-undef
    }
  }
}
export default nativeReader;