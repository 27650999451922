import React from "react"
import { RadioBox } from "../helper";
import { Translate } from "react-localize-redux";

export class HearingHX extends RadioBox {
	constructor(props) {
		super(props, props.setState)
	}
	render() {
		return (
			<Translate key={this.props.template.id}>
				{({ translate }) => 
					<div className="oc-form-group-wrapper">
						<h6>{ this.props.template.name }</h6>
                        <ul>
                            <li>
                                <label
                                    className={`
                                        oc-form-label
                                        ${this.state.value === 'มี' ? 'active': ''}
                                    `.trim()}
                                >
                                    {translate('have')}
                                    <input
                                        name={`${this.props.template.id}`}
                                        type="radio"
                                        value="มี"
                                        checked={this.state.value === 'มี'}
                                        onChange={(e) => this.onChangeHandler(e)}
                                    />
                                </label>
                                <label
                                    style={{
                                        display: this.state.value === 'มี' ? 'inline-block': 'none'
                                    }}
                                >
                                    <input
                                        name={`${this.props.template.id}-note`}
                                        type="text"
                                        value={this.state.note}
                                        onChange={(e) => this.onChangeHandler(e)}
                                    />
                                </label>
                            </li>
                            <li>
                                <label
                                    className={`
                                        oc-form-label
                                        ${this.state.value === 'ไม่มี' ? 'active': ''}
                                    `.trim()}
                                >
                                    {translate('dont_have')}
                                    <input
                                        name={`${this.props.template.id}`}
                                        type="radio"
                                        value="ไม่มี"
                                        checked={this.state.value === 'ไม่มี'}
                                        onChange={(e) => this.onChangeHandler(e)}
                                    />
                                </label>
                            </li>
                        </ul>
					</div>
				}
			</Translate>
		)
	}
}