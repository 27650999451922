import React from "react"
import { TextBox } from "./helper";
import { Translate } from "react-localize-redux";

export class Comment extends TextBox {
    constructor(props) {
		super(props, props.setState)
    }
    render() {
        return (
			<Translate key={this.props.template.id}>
                {({ translate }) => 
                <div>
                    <label>
                        {this.props.template.name}
                        <input
                            style={{marginLeft: '16px', width: 'calc(100% - 70px - 16px)'}}
                            name="comment"
                            type="text"
                            value={this.state.value}
                            onChange={(e) => this.onChangeHandler(e)}
                        />
                    </label>
                </div>
                }
            </Translate>
        )
    }
}