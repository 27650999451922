import React, { Component } from "react";

import Logout from '../../components/Logout';
import Loading from '../../components/Loading';

import authFetch from '../../services/authFetch';
import { Translate } from "react-localize-redux";

String.prototype.replaceAll = function replaceAll(search, replace) { return this.split(search).join(replace); }
class ManagementPEPrint extends Component {
  constructor(props) {
    super(props);

    this.state = {
      vn: this.props.match.params.vn,
      patient: null,
      html_print: "",
      cert_list: null
    };
    this.goBack = this.goBack.bind(this);
    this.goBafetchCertck = this.fetchCert.bind(this);
    this.chooseMc = this.chooseMc.bind(this);
  }

  componentDidMount() {
    authFetch.get('medical-cert/list', (response) => {
      if(response) {
        // console.log(response)
        this.setState({ cert_list: response })
      } else {
        this.fetchCert('forign');
      }
    });
  }

  fetchCert(cert_type) {
    authFetch.get('visit/' + this.state.vn + '/print-medical-cert/'+cert_type, (response) => {
      if(response) {
        response = response.replaceAll('<input type="checkbox">', '<i class="far fa-square"> </i>');
        response = response.replaceAll('<input type="checkbox" checked>', '<i class="far fa-check-square"> </i>');
        response = response.replaceAll('<input type="checkbox" checked="checked">', '<i class="far fa-check-square"> </i>');
      } else {
        response = "<h1>No Data</h1>";
      }
      this.setState({ html_print: response })
      setTimeout(() => {
        this.doPrint();
      }, 300)
    });
  }

  chooseMc() {
    this.setState({ html_print: null })
  }

  doPrint() {
    let printStyle = document.getElementById('specific-print');
    let style = document.createTextNode('@page { size: A4;margin:0.5cm 1em; }body{margin:0; padding:0;}')
    printStyle.appendChild(style)
    window.print();
  }

  goBack() {
    var backTo = '/management/pe_search';
    if (this.props.location.state && this.props.location.state.backTo) {
      backTo = this.props.location.state.backTo;
    }
    this.props.history.push(backTo);
  }

  render() {
    return (
      <Translate>
        {({ translate }) =>
          <div className="management-page page">
            <header className="page-header">
              <button className="link-page-back" onClick={this.goBack}>
                {translate("back")}
              </button>
              <h1>{translate("medical_certificate")}</h1>
              <Logout />
            </header>

            <main className="page-content">
              <div className="page-content-main">
                <div className="register-pe">
                  {this.state.html_print ? (
                    <div className="pe-print-wrapper">
                      <div className="pe_print_display mc-print-display">
                      <div dangerouslySetInnerHTML={{ __html: this.state.html_print }} />
                      </div>
                      <div className="pe-print-buttons">
                        <h6>{translate('print_medical_certificate')}</h6>
                        <p>
                          <button className="btn-round btn-block" onClick={this.doPrint}>
                            {translate("print_again")}
                          </button>
                        </p>
                        {this.state.cert_list ? (
                        <p>
                          <button className="btn-round btn-block btn-line" onClick={this.chooseMc}>
                            {translate("choose_other_mc_type")}
                          </button>
                        </p>
                        ):null}
                        <p>
                          <button className="btn-round btn-black btn-block" onClick={this.goBack}>
                            {translate("close")}
                          </button>
                        </p>
                      </div>
                    </div>
                  ) : (

                    <div>
                      {this.state.cert_list ? (
                        <div>
                          {this.state.cert_list.map((item, k) =>
                            <p key={item.handle}>
                              <button className="btn btn-block btn-round"
												      onClick={() => this.fetchCert(item.handle) }>{item.display} <i className="fa fa-print"></i></button>
                            </p>
                          )}
                        </div>
                      ) : (
                        <Loading show={true} />
                      )}
                    </div>
                    
                  )}
                </div>
              </div>
            </main>
          </div>
        }
      </Translate>
    );
  }
}

export default ManagementPEPrint;
