import React, { Component } from "react";
import { Translate } from "react-localize-redux";

class PresetSelect extends Component {

	constructor(props) {
		super(props);

		this.state = {
			show_modal: false
		};

		this.showModel = this.showModel.bind(this);
		this.hideModel = this.hideModel.bind(this);
	}

	componentDidMount() {
		
	}

	showModel() {
		this.setState({ show_modal: true })
	}

	hideModel() {
		this.setState({ show_modal: false  })
	}
	
	render() {
		return (
			<Translate>
				{({ translate }) =>
					<div>
						{this.state.show_modal===false ? 
							<button className="btn-subtle" onClick={this.showModel}><small><i className="fas fa-plus"></i></small> Add Preset Text</button>
						:
							<div className="modal modal-lg">
								<div className="modal-body">
									<div className="modal-header">
										Select Preset
										<i onClick={this.hideModel}>×</i>
									</div>
									<div className="modal-content">
										{this.props.field.presets.map((item, index) => {
											return (
												<div className="prop-item" key={'prop-list-'+index} onClick={()=>{this.hideModel();this.props.selected(this.props.field,item)}}>
													{item}
												</div>
											)
										})}
									</div>
								</div>
							</div>
						}
					</div>
				}
			</Translate>
		);
	}
}

export default PresetSelect;