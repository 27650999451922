import React, { Component } from "react";
import { Translate } from "react-localize-redux";

import InlineLoading from '../components/InlineLoading';
import authFetch from '../services/authFetch';

class ProgressOverview extends Component {
	intervalID = 0;
	timeoutID = 0;

	constructor(props) {
		super(props);

		this.state = {
			specimens: null,
			visit: { all: 0, finished: 0, registered: 0 }
		};

		this.update = this.update.bind(this);
		this.gotoSpecimen = this.gotoSpecimen.bind(this);
	}

	componentDidMount() {
		//this.intervalID = setInterval(this.update, 5000)
		this.update();
	}

	componentWillUnmount() {
		//clearInterval(this.intervalID);
		clearTimeout(this.timeoutID);
	}

	gotoSpecimen(id) {
		this.props.history.push('/management/specimens_all/' + id);
	}

	update() {
		authFetch.get('specimens/count', (result) => {
			//console.log(result)
			if (result.success) {
				this.setState({ specimens: result.count, visit: result.visit });
			}
			this.timeoutID = setTimeout(() => { this.update() }, 5000);
		});
	}

	render() {

		return (
			<Translate>
				{({ translate }) =>
					<div className="progress-overview">
						{this.state.specimens !== null ? (
							<div>
								<table className="page-content-aside-table">
									<tbody>
										<tr>
											<td className="text-left">{translate("registered_patients")}</td>
											<td className="text-center" width="100">
												<div className="progress-count"><span style={{ width: ((this.state.visit.registered / this.state.visit.all) * 100) + "%" }}></span><em>{this.state.visit.registered}/{this.state.visit.all}</em></div>
											</td>
										</tr>
									</tbody>
								</table>
								<table className="page-content-aside-table">
									<thead>
										<tr>
											<th className="text-left">{translate("specimen")}</th>
											<th className="text-center" width="100">{translate("count")}</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td className="text-left">{translate("bp")}</td>
											<td className="text-center" width="100">
												<div className="progress-count"><span style={{ width: ((this.state.visit.bp / this.state.visit.all) * 100) + "%" }}></span><em>{this.state.visit.bp}/{this.state.visit.all}</em></div>
											</td>
										</tr>
										<tr>
											<td className="text-left">{translate("weight")}</td>
											<td className="text-center" width="100">
												<div className="progress-count"><span style={{ width: ((this.state.visit.wt / this.state.visit.all) * 100) + "%" }}></span><em>{this.state.visit.wt}/{this.state.visit.all}</em></div>
											</td>
										</tr>
										<tr>
											<td className="text-left">{translate("height")}</td>
											<td className="text-center" width="100">
												<div className="progress-count"><span style={{ width: ((this.state.visit.ht / this.state.visit.all) * 100) + "%" }}></span><em>{this.state.visit.ht}/{this.state.visit.all}</em></div>
											</td>
										</tr>
										{this.state.specimens.map((item, index) => {
											return (<tr key={index} className="cursor-pointer" onClick={() => this.gotoSpecimen(item.specimen_id)}>
												<td className="text-left">
													{item.name}
												</td>
												<td className="text-center">
													<div className="progress-count"><span style={{ width: ((item.count_received / item.count_all) * 100) + "%" }}></span><em>{item.count_received}/{item.count_all}</em></div>
												</td>
											</tr>)
										})}
									</tbody>
								</table>
							</div>
						) : (
							<InlineLoading show={true} />
						)}
					</div>
				}
			</Translate>
		);
	}
}

export default ProgressOverview;