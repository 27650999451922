import React from "react"
import { CheckBox } from "./helper";
import { Translate } from "react-localize-redux";

export class VHX extends CheckBox {
	constructor(props) {
		super(props, props.setState)
	}
	componentDidMount() {
		this.setState({
			value: this.props.template.value.split(','),
			note: this.props.template.note
		})
	}
	isOther() {
		const options = ['มองใกล้ไม่ชัด', 'มองไกลไม่ชัด', 'เห็นภาพซ้อน', 'ตาบอดสี', 'ไม่มี']
		const filtered = this.state.value.filter(x => !options.includes(x) )
		if (filtered.length === 0) {
			return false
		}
		return true
	}
	render() {
		return (
			<Translate key={this.props.template.id}>
				{({ translate }) => 
					<div className="oc-form-group-wrapper checkbox">
						<h6>{ this.props.template.name }</h6>
						<ul>
							<li>
								<label
									className={`
										oc-form-label
										${this.state.value.includes('มองใกล้ไม่ชัด') ? 'active': ''}
									`.trim()}
								>
									<input
										name="vhx-1"
										type="checkbox"
										value="มองใกล้ไม่ชัด"
										checked={this.state.value.includes('มองใกล้ไม่ชัด')}
										onChange={(e) => this.onChangeHandler(e)}
									/>
									{ translate("form_vision_problem_near_blur") }
								</label>
							</li>
							<li>
								<label
									className={`
										oc-form-label
										${this.state.value.includes('มองไกลไม่ชัด') ? 'active': ''}
									`.trim()}
								>
									<input
										name="vhx-2"
										type="checkbox"
										value="มองไกลไม่ชัด"
										checked={this.state.value.includes('มองไกลไม่ชัด')}
										onChange={(e) => this.onChangeHandler(e)}
									/>
									{ translate("form_vision_problem_far_blur") }
								</label>
							</li>
							<li>
								<label
									className={`
										oc-form-label
										${this.state.value.includes('เห็นภาพซ้อน') ? 'active': ''}
									`.trim()}
								>
									<input
										name="vhx-3"
										type="checkbox"
										value="เห็นภาพซ้อน"
										checked={this.state.value.includes('เห็นภาพซ้อน')}
										onChange={(e) => this.onChangeHandler(e)}
									/>
									{ translate("form_vision_problem_illusion") }
								</label>
							</li>
							<li>
								<label
									className={`
										oc-form-label
										${this.state.value.includes('ตาบอดสี') ? 'active': ''}
									`.trim()}
								>
									<input
										name="vhx-4"
										type="checkbox"
										value="ตาบอดสี"
										checked={this.state.value.includes('ตาบอดสี')}
										onChange={(e) => this.onChangeHandler(e)}
									/> 
									{ translate("form_vision_problem_colorblind") }
								</label>
							</li>
							<li>
								<label
									className={`
										oc-form-label
										${this.isOther() ? 'active': ''}
									`.trim()}
								>
									<input
										name="vhx-5"
										type="checkbox"
										value="(อื่นๆ)"
										checked={this.isOther()}
										onChange={(e) => this.onChangeHandler(e)}
									/> 
									{ translate("other") }
								</label>
								<label
								>
									<input
										name="vhx-note"
										type="text"
										value={this.state.note}
										onChange={(e) => this.onChangeHandler(e)}
									/>
								</label>
							</li>
						</ul>
					</div>
				}
			</Translate>
		)
	}
}