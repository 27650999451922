import React, { Component } from "react";

import Logout from '../../components/Logout';
import Loading from '../../components/Loading';
import PresetSelect from '../../components/PresetSelect';
import ViewPDF from '../../components/ViewPDF';
import Auth from '../../helpers/Auth';
import authFetch from '../../services/authFetch';
import { Translate } from "react-localize-redux";

class ManagementOtherForm extends Component {
	constructor(props) {
		super(props);

		this.state = {
			vn: this.props.match.params.vn,
			oid: this.props.match.params.oid,
			visit: null,
			order: null,
			observations: null,
			show_modal: false,
			loading: true,
			practitioners: [],
			is_doctor: false
		};

		this.doSubmit = this.doSubmit.bind(this);
		this.handleTextChange = this.handleTextChange.bind(this);
		this.addPresetText = this.addPresetText.bind(this);
		
		
		this.afterSave = this.afterSave.bind(this);
		this.loadVisit = this.loadVisit.bind(this);
		this.goBack = this.goBack.bind(this);
		this.ctrlSubmit = this.ctrlSubmit.bind(this);
		this.handlePractitionerChange = this.handlePractitionerChange.bind(this);
	}

	componentWillUnmount() {
		document.removeEventListener("keydown", this.ctrlSubmit, false);
	}

	ctrlSubmit(e) {
		if (e.ctrlKey && e.keyCode == 13) {
			// Ctrl-Enter pressed
			this.doSubmit(e);
		}
		return;
	}

	componentDidMount() {
		document.addEventListener("keydown", this.ctrlSubmit, false);
		this.loadVisit();
	}

	handlePractitionerChange(event) {
		const target = event.target;
		const value = (target.type === 'checkbox' || target.type === 'radio') ? target.value : target.value;
		this.setState({ practitioner_id: value });
	}

	loadVisit() {
		authFetch.get('other/order/' + this.state.oid + "?vn="+this.state.vn, (result) => {
			if (result.success && result.visit) {
				let practitioner_id = result.practitioner_id;
				var is_doctor = false;
				// console.log(Auth)
				if (!practitioner_id) {
					if (Auth.user_role === 'doctor') {
						practitioner_id = Auth.user_id;
						is_doctor = true;
					}
				}

				this.setState({ 
					visit: result.visit, 
					observations: result.visit.result, 
					order: result.order,
					practitioners: result.practitioners,
					practitioner_id: practitioner_id,
					is_doctor: is_doctor
				});
				// this.setState({ order: {file_url:"https://www.africau.edu/images/default/sample.pdf"} } );
				setTimeout(()=>{
					let elements = document.getElementsByClassName('autosize');
					for(let i=0;i<elements.length;i++) {
						this.changeHeightTextarea(elements[i])
					}
				},300)
			} else {
				this.setState({ visit: [] });
			}
			this.setState({ loading: false });
		});
	}

	handleTextChange(selected, event) {
		let items = [];
		this.state.observations.map((item, index) => {
			if (item.observation_id === selected.observation_id) {
				item.value = event.target.value;
				this.changeHeightTextarea(event.target)
			}
			items.push(item);
		});
		this.setState({ observations: items  });
	}

	changeHeightTextarea(target) {
		target.style.height = 'auto';
		target.style.height = (target.scrollHeight+5)+'px'
	}

	doSubmit(e, action) {
		e.preventDefault();
		this.setState({ loading: true });
		var obs = [];
		this.state.observations.map((item, index) => {
			obs.push({ observation_id: item.observation_id, value: item.value, note: item.note, vn:this.state.visit.vn })
		});

		var data = {
			vn: this.state.visit.vn,
			result: obs,
			practitioner_id: this.state.practitioner_id
		}
		
		authFetch.post('other/order/'+this.state.order.id, data, (result) => {
			this.afterSave(result, action)
		});
		return false;
	}

	addPresetText(current,text) {
		let items = [];
		console.log(text)
		this.state.observations.map((item, index) => {
			if (item.observation_id === current.observation_id) {
				if(item.value) {
					item.value += "\n";
				} else {
					item.value = ""
				}
				item.value += text
			}
			items.push(item);
		});
		this.setState({ observations: items  });
	}

	goBack() {
		var backTo = '/management/other_list/'+this.state.vn;
		if (this.props.location.state && this.props.location.state.backTo) {
			backTo = this.props.location.state.backTo;
		}
		this.props.history.push(backTo);
	}

	afterSave(result, action) {
		if (result.success) {
			this.props.history.push('/management/other_search')
		} else {
			this.setState({ loading: false });
			if (result.errors) window.doAlert(result.errors.join('\n'))
			else {
				// console.log(result);
				window.doAlert("Server Response Error");
			}
		}
	}

	render() {
		return (
			<Translate>
				{({ translate }) =>
					<div className="management-page page">
						<header className="page-header">
							<button className="link-page-back" onClick={this.goBack}>
								{translate("back")}
							</button>
							{this.state.order?<h1>{this.state.order.name}</h1>:null}
							<Logout />
						</header>

						<main className="page-content">
							{this.state.visit ? (
								<div className="pe-form-wrapper">
									<div className="patient-info-card">
										<div>{translate("name")}: <strong>{this.state.visit.fullname}</strong> <small>({translate("vn")}: {this.state.visit.vn})</small></div>
										<div className="patient-info-list">
											<span>{translate("age")}: {this.state.visit.age}</span>
											<span>{translate("weight")}: {this.state.visit.wt}</span>
											<span>{translate("height")}: {this.state.visit.ht}</span>
											<span>{translate("blood_pressure")}: {this.state.visit.bp}</span>
										</div>
									</div>
									<div className="pe-form-body">
										{this.state.observations && Array.isArray(this.state.observations) && this.state.observations.length > 0?
										<div className="other-order-list">
											{this.state.observations.map((item, index) => {
												return (
													<div className="other-order-list-item" key={'other-list-'+index}>
														<div className="other-order-list-item-title">{item.observation_name}:
															{this.state.order && this.state.order.file_url?(
																<div className="view-pdf-link">
																	<ViewPDF file={this.state.order.file_url}/>
																</div>
															):null}
														</div>
														<div className="other-order-list-item-content">
															<div className="other-order-list-item-note">
																<textarea rows="3" className="other-order-note autosize" onChange={(event) => this.handleTextChange(item, event)} value={item.value?item.value:''} />
																{(item.presets && Array.isArray(item.presets) && item.presets.length > 0)?
																	<PresetSelect field={item} selected={this.addPresetText}/>
																:null}
															</div>
														</div>
													</div>
												)
											})}
											{this.state.practitioners ? (
											<div className="other-order-list-item">
													<div className="other-order-list-item-title">{translate("select_doctor")} </div>
													<div className="other-order-list-item-content">
														<select className="form-control" onChange={this.handlePractitionerChange}
															value={this.state.practitioner_id ? this.state.practitioner_id : ""} style={{'max-width':'400px'}}>
															<option value="">{translate("not_specific")}</option>
															{this.state.practitioners.map((item, index) => {
																return (<option value={item.id} key={index}>{item.fullname} ({item.lid??'-'})</option>)
															})}
														</select>
													</div>
												</div>
											) : null}
										</div>
										:
											<div>
												<h4>{translate("no_data")}</h4>
											</div>
										}
									</div>
									<div className="pe-form-footer">
										<div className="pe-form-footer-info">
											<p><strong>{translate("name")}</strong>: {this.state.visit.fullname}</p>
											<p><strong>{translate("vn")}</strong>: {this.state.visit.vn}</p>
										</div>

										<div className="pe-form-footer-button">
											<button className="btn-round btn-block" onClick={(event) => this.doSubmit(event)}>{translate("save_data")}</button>
										</div>
									</div>
								</div>
							) : (null)}
							<Loading show={this.state.loading} />
						</main>
					</div>
				}
			</Translate>
		);
	}
}

export default ManagementOtherForm;
