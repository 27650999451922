import React, { Component } from "react";
import {
  Link,
} from "react-router-dom";

import Logout from '../../components/Logout';
import InlineLoading from '../../components/InlineLoading';

import SortArrow from '../../components/SortArrow';
import authFetch from '../../services/authFetch';
import { Translate } from "react-localize-redux";

class Register extends Component {
	constructor(props) {
    super(props);
    this.state = {
	    oriData: [],
	    data: null,
	    sortDesc: false,
	    sort:""
    };
    
    this.filterSearch = this.filterSearch.bind(this);
    this.compareBy = this.compareBy.bind(this);
    this.sortBy = this.sortBy.bind(this);
  }
    
  componentDidMount() {
	  authFetch.get('visits',(result)=>{
		  if(result.success){
		    this.setState({oriData:result.visits,data:result.visits});
	    }
	  });
  }
  
  compareBy(key,sortDesc) {
    return function (a, b) {
      a[key] = (a[key]===null) ? "":a[key];
      b[key] = (b[key]===null) ? "":b[key];
	    if(sortDesc)
	    {			    
	      if (a[key] < b[key]) return -1;
	      if (a[key] > b[key]) return 1;
	    } else {
	      if (a[key] < b[key]) return 1;
	      if (a[key] > b[key]) return -1;
	    }
      return 0;
    };
  }
 
  sortBy(key) {
	  var sortDesc = this.state.sortDesc;
    let arrayCopy = [...this.state.data];
    if(key===this.state.sort)sortDesc=!sortDesc;
    arrayCopy.sort(this.compareBy(key,sortDesc));
    this.setState({data: arrayCopy,sortDesc:sortDesc,sort:key});
  }
  
  filterSearch(e)
  {
	  var value = e.target.value;
	  if(value){
		  var data =  this.state.oriData.filter(function(item) {
				return (item.vn.indexOf(value) !== -1 || item.fullname.indexOf(value) !== -1 || item.eid.indexOf(value) !== -1);
			});
	  }else{
		  var data =  this.state.oriData
	  }
	  this.setState({data:data},()=>{this.sortBy(this.state.sort)})
  }

  render() {
    return (
			<Translate>
			{({ translate }) => 
	      <div className="register-page">
	      	<div className="page-header">      	
		      	<button className="link-page-back" onClick={()=>this.props.history.push('/register')}>{translate("back")}</button>
		      	<h1>{translate("all_patients")}</h1>
		      	<Logout/>
	      	</div>
	      	
	      	<div className="page-content">
		      	<div className="page-content-main">
			      	
			      	<div className="page-content-header">
				      	<div>
					      	<div className="search-form">
			      				<input type="text" className="form-control" placeholder={translate("search")} onChange={this.filterSearch} autoFocus />
			      				<button type="button" className="search-form-btn"><i className="fa fa-search"></i></button>
		      				</div>
					      </div>
					      <div></div>
				      	<div className="text-right">
					      	<Link className="btn btn-link" to={'/register'}>
				      			{translate("close_page")} <img src={require('assets/images/icon-minimize.svg')} width="30" />
			      			</Link>
			      		</div>
	      			</div>
		      		{this.state.data!==null  ? (
		      			<table className="light-table">
			      			<thead>
		      					<tr>
		      						<th className="text-center" onClick={()=>{this.sortBy('vn')}}>
							      		{this.state.sort==="vn"?(<SortArrow desc={this.state.sortDesc}/>):(null)}
							      		VN
							      	</th>
		      						<th className="text-center" onClick={()=>{this.sortBy('fullname')}}>
							      		{this.state.sort==="fullname"?(<SortArrow desc={this.state.sortDesc}/>):(null)}
							      		{translate("name")}
							      	</th>
		      						<th className="text-center" onClick={()=>{this.sortBy('eid')}}>
							      		{this.state.sort==="eid"?(<SortArrow desc={this.state.sortDesc}/>):(null)}
							      		{translate("employee_id")}
							      	</th>
		      						<th className="text-center" onClick={()=>{this.sortBy('registered_at')}}>
							      		{this.state.sort==="registered_at"?(<SortArrow desc={this.state.sortDesc}/>):(null)}
							      		{translate("status")}</th>
		      						<th className="text-center" width="20">{translate("edit")}</th>
		      					</tr>
		      				</thead>
		      				<tbody>
			      				{this.state.data.map((item, index)=>{
											return (<tr key={ index } onClick={()=>this.props.history.push('/register/form/'+item.vn)}>
			      						<td className="text-center">{item.vn}</td>
			      						<td className="text-center">{item.fullname}</td>
			      						<td className="text-center">{item.eid}</td>
			      						<td className="text-center">
			      							{(item.registered_at)?
							      				<span>{translate("registered")}</span>
							      				:
							      				<span>{translate("not_registered")}</span>
							      			}
			      						</td>
			      						
			      						<td className="text-center">
				      						<Link to={'/register/form/'+item.vn}>
								      			<img src={require('assets/images/icon-edit.svg')} width="20" />
							      			</Link>	
				      					</td>
			      					</tr>)
								    })}
		      				</tbody>
			      		</table>
					  	):(
					  		<InlineLoading show={true}/>
					  	)}
		      	</div>
		      </div>
	      </div>
      }
			</Translate>
    );
  }
}

export default Register;
