import React, { Component } from "react";

class ShowValidate extends Component {
	constructor(props) {
    super(props);
  }
  
  render() {
	  
	  if(this.props.validate && this.props.validate.length>0){		  
		  return <div className="validate-error">
			  <div className="validate-error-items">
				  <i className="fas fa-exclamation-circle"></i>
				  {/*this.props.validate.map((item,index)=><span key={index}>{item}</span>)*/}
				</div>
		  </div>
	  } else {
		  return null;
	  }
  }
}

export default ShowValidate;