import authFetch from '../services/authFetch';

const Auth = {
	isAuthenticated: false,
	token: null,
	unit_id: null,
	unit_name: null,
	user_id: null,
	user_fullname: null,
	user_lid: null,
	user_role: null,
	init() {
		var token = localStorage.getItem('token');
		var unit_id = localStorage.getItem('unit_id');
		var unit_name = localStorage.getItem('unit_name');

		var user_id = localStorage.getItem('user_id');
		var user_fullname = localStorage.getItem('user_fullname');
		var user_lid = localStorage.getItem('user_lid');
		var user_role = localStorage.getItem('user_role');

		if (token && unit_id) {
			this.isAuthenticated = true;
			this.token = token;
			this.unit_id = unit_id;
			this.unit_name = unit_name;

			this.user_id = user_id;
			this.user_fullname = user_fullname;
			this.user_lid = user_lid;
			this.user_role = user_role;
		}
	},
	authenticate(credentials, cb) {
		let settings = {
			'grant_type': 'password',
			'client_id': process.env.REACT_APP_CLIENT_ID??'2',
			'client_secret': process.env.REACT_APP_CLIENT_SECRET??'abc'
		};
		let data = { ...settings, ...credentials };
		//authFetch.post('login');
		authFetch.post('login', data, (resp) => {
			if (resp.success === 1 && resp.token !== undefined) {
				console.log(resp)
				localStorage.setItem('token', resp.token.access_token);
				localStorage.setItem('user_id', resp.user.id);
				localStorage.setItem('user_fullname', resp.user.fullname);
				localStorage.setItem('user_lid', resp.user.lid);
				localStorage.setItem('user_role', resp.user.role_handle);

				localStorage.setItem('unit_id', credentials.unit_id);
				localStorage.setItem('unit_name', credentials.unit_name);

				this.isAuthenticated = true;
				this.token = resp.token.access_token;
				this.unit_id = credentials.unit_id;
				this.unit_name = credentials.unit_name;

				this.user_id = resp.user.id;
				this.user_fullname = resp.user.fullname;
				this.user_lid = resp.user.lid;
				this.user_role = resp.user.role_handle;
				cb(true);
			} else {
				this.isAuthenticated = false;
				cb(false, resp.errors);
			}
		});
	},
	switch_unit(unit) {
		localStorage.setItem('unit_id', unit.id);
		localStorage.setItem('unit_name', unit.name);

		this.unit_id = unit.id;
		this.unit_name = unit.name;
	},
	logout(cb) {
		localStorage.removeItem('token');
		localStorage.removeItem('unit_id');
		localStorage.removeItem('unit_name');
		localStorage.removeItem('user_id');
		localStorage.removeItem('user_fullname');
		localStorage.removeItem('user_lid');
		localStorage.removeItem('user_role');
		this.isAuthenticated = false;
		this.token = null;
		this.unit_id = null;
		this.unit_name = null;
		this.user_id = null;
		this.user_fullname = null;
		this.user_lid = null;
		this.user_role = null;
		window.location = "/";
	}
};
export default Auth;