import React from "react"
import { RadioBox } from "./helper";
import { Translate } from "react-localize-redux";

export class Glasses extends RadioBox {
	constructor(props) {
		super(props, props.setState)
	}
	render() {
		return (
			<Translate key={this.props.template.id}>
				{({ translate }) => 
					<div className="oc-form-group-wrapper">
						<h6>{this.props.template.name}</h6>
						<ul>
							<li>
								<label
									className={`
										oc-form-label
										${this.state.value === '1' ? 'active': ''}
									`.trim()}
								>
									<input
										name="glasses"
										type="radio"
										value="1"
										onChange={(e) => this.onChangeHandler(e)}
										checked={this.state.value === "1"}
									/>
									{ translate("form_vision_glasses_yes") }
								</label>
							</li>
							<li>
								<label
									className={`
										oc-form-label
										${this.state.value === '0' ? 'active': ''}
									`.trim()}
								>
									<input
										name="glasses"
										type="radio"
										value="0"
										onChange={(e) => this.onChangeHandler(e)}
										checked={this.state.value === "0"}
									/>
									{ translate("form_vision_glasses_no") }
								</label>
							</li>
						</ul>
					</div>
				}
			</Translate>
		)
	}
}