import React, { Component } from "react";
import {
  Link,
} from "react-router-dom";

import authFetch from '../../services/authFetch';

import Logout from '../../components/Logout';
import InlineLoading from '../../components/InlineLoading';
import CardReader from '../../components/CardReader';

import { Translate } from "react-localize-redux";

class ManagementVisit extends Component {
	constructor(props) {
    super(props);
    this.state = {
	    previous: null
    };
    
    this.doSearch = this.doSearch.bind(this);
  }
  
  componentDidMount() {
	  authFetch.get('visits/recent',(result)=>{
		  if(result.success){
		    this.setState({previous:result.visits});
	    }
	  });
  }
  
  doSearch(e) {
	  e.preventDefault();
	  this.props.history.push('/register/search');
	}
  

  render() {
    return (
			<Translate>
			{({ translate }) => 
	      <div className="register-page page">
	      	<header className="page-header">
		      	<Link className="link-page-back" to={'/management'}>
	      			{translate("back")}
	      		</Link>
		      	<h1>{translate("edit_patients")}</h1>
		      	<Logout/>
	      	</header>
	      	
	      	<main className="page-content">
	      		<div className="page-content-padded">
		      		<div className="action-box">
		      			<div className="action-box-image">
		      				<img src={require('assets/images/icon-card.svg')} />
		      			</div>
		      			<div className="action-box-content">
		      				<p><strong>{translate("read_citizen_card")}</strong></p>
	      					1. {translate("insert_card")} <span className="spacer"/>
	      					2. {translate("click")} <span className="spacer"/>
									<CardReader/>
		      			</div>
	      			</div>
	      			
	      			<p className="text-center"><strong>{translate("or")}</strong></p>
	      			
		      		<div className="action-box">
		      			<div className="action-box-image">
		      				<img src={require('assets/images/icon-search.svg')} />
		      			</div>
		      			<div className="action-box-content">
			      			<p><strong>{translate("search_database")}</strong> <small>({translate("type_name_cid_eid")})</small></p>
			      			
		      				<form onSubmit={(e)=>this.doSearch(e)} className="search-form">
			      				<input type="text" className="form-control" placeholder={translate("search")} autoFocus />
			      				<button type="submit" className="search-form-btn"><i className="fa fa-search"></i></button>
		      				</form>
		      			</div>
	      			</div>
	      			
	      			<p className="text-center"><strong>{translate("or")}</strong></p>
	      			
			      	<Link to={'/register/form'} className="btn-round btn-block">
			      		<img src={require('assets/images/icon-plus.svg')} className="btn-icon-image" />
			      		{translate("add_patient")}
			      	</Link>
	      		</div>
		      </main>
	      </div>
      }
			</Translate>
    );
  }
}

export default ManagementVisit;
