import React, { Component } from "react";

import Logout from '../../components/Logout';
import Loading from '../../components/Loading';

import nativePrint from '../../helpers/NativePrint';

import authFetch from '../../services/authFetch';
import { Translate } from "react-localize-redux";

class ManagementVisitSpecimen extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			vn: this.props.match.params.vn,
			all: false,
			selected: "",
			stickers: []
		};

		this.togglePrint = this.togglePrint.bind(this);
		this.printStickers = this.printStickers.bind(this);
		this.afterPrint = this.afterPrint.bind(this);
		this.goBack = this.goBack.bind(this);
		this.toggleAll = this.toggleAll.bind(this);
	}

	componentDidMount() {
		authFetch.get('specimens', (result) => {
			console.log(result)
			if (result.success) {
				this.setState({ loading: false, stickers: result.specimens });
			}
		});
	}

	togglePrint(id, check) {
		if (check) this.setState({ selected: id })
		else this.setState({ selected: "" })
	}

	toggleAll(check) {
		var stickers = this.state.stickers;
		var result = [];
		stickers.map((item, index) => {
			item.print = check;
			result.push(item);
		});
		this.setState({ all: check, stickers: result })
	}

	printStickers() {
		var stickers = [];
		console.log(this.state.selected)

		if(this.state.selected){
			authFetch.get('specimens/not-print/'+this.state.selected, (result) => {
				console.log(result)
				if (result.success) {
					stickers = result.specimens;
				}

				if (stickers.length > 0) {
					nativePrint(stickers, this.afterPrint);
				} else {
					window.doAlert("ไม่มีสติ๊กเกอร์ที่สามารถพิมพ์ได้");
				}
			});
		}

	}

	afterPrint(success, stickers) {
		if (success) {
			var prints = [];
			var specimens = stickers.map((item, index) => {
				prints.push(item.id);
			});
			var data = { specimens: prints }
			authFetch.put('visit/stickers', data, (result) => {
				if (result.success) {
					this.goBack();
				} else {
					this.setState({ loading: false });
					window.doConfirm('error_save_print_status_exit', this.goBack, () => { });
				}
			});
		} else {
			this.setState({ loading: false });
			window.doConfirm('error_printing_exit', this.goBack, () => { });
		}
	}

	goBack() {
		var backTo = '/management';
		if (this.props.location.state && this.props.location.state.backTo) {
			backTo = this.props.location.state.backTo;
		}
		this.props.history.push(backTo);
	}

	render() {

		return (
			<Translate>
				{({ translate }) =>
					<div className="management-page page">
						<header className="page-header">
							<button className="link-page-back" onClick={() => this.goBack()}>
								{translate("back")}
							</button>
							<h1>{translate("print_sticker")}</h1>
							<Logout />
						</header>

						<main className="page-content">
							<div className="page-content-main">
								{(this.state.stickers.length > 0) ?
									(
										<div>
											<table className="light-table">
												<thead>
													<tr>
														<th>{translate("specimen")}</th>
														<th className="text-center">{translate("print")}</th>
													</tr>
												</thead>
												<tbody>
													{this.state.stickers.map((item, index) => {
														return (
															<tr key={index}>
																<td className="text-center">{item.name}</td>

																<td className="text-center" width="70">
																	{item.id === this.state.selected ? (
																		<button className="mini-radio" onClick={() => this.togglePrint(item.id, false)}>
																			<i className="fa fa-check"></i>
																		</button>
																	) : (
																		<button className="mini-radio" onClick={() => this.togglePrint(item.id, true)}>
																			<i></i>
																		</button>
																	)}
																</td>
															</tr>
														)
													})}

												</tbody>
											</table>
											<br />
											<p className="text-right">
												<button type="button" className="btn btn-round" onClick={this.printStickers}>
													{translate('print_sticker')} <i className="fa fa-print"></i>
												</button>
											</p>
										</div>
									) : (null)
								}
							</div>
							<Loading show={this.state.loading} />
						</main>
					</div>
				}
			</Translate>
		);
	}
}

export default ManagementVisitSpecimen;
