import React from "react";
import { withLocalize } from "react-localize-redux";

const LanguageToggle = ({ languages, activeLanguage, setActiveLanguage }) => (
  <div id="languageToggle">
    {languages.map(lang => (
      <button key={lang.code} onClick={() => setActiveLanguage(lang.code)}>
        {lang.name}
      </button>
    ))}
  </div>
);

export default withLocalize(LanguageToggle);