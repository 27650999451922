import React from 'react';
import moment from 'moment';
import 'moment/locale/th';

import { Translate } from "react-localize-redux";

export default class BirthdaySelect extends React.Component {
	constructor(props) {
		super(props);
		
		moment.locale('th');
		
		const { value } = props;
		
		let defaultDate = null;
		if(value)defaultDate=moment(value);
		
		this.state = {
			day: null,
			month: null,
			year: null,
			age: null,
			selectDay: (defaultDate)?defaultDate.format('D'):"",
			selectMonth: (defaultDate)?defaultDate.format('M'):"",
			selectYear: (defaultDate)?defaultDate.format('Y'):"",
		};
		
    this.updateDate = this.updateDate.bind(this);
	}
	
	componentDidMount() {
	  if (this.props.value) {
	    this.updateDate(moment(this.props.value));
	  }
	}
	
	componentDidUpdate(prevProps) {
	  if (this.props.value && this.props.value !== prevProps.value) {
	    this.updateDate(moment(this.props.value));
	  }
	}
	
	updateDate(defaultDate)
	{
		this.setState({
			selectDay: (defaultDate)?defaultDate.format('D'):"",
			selectMonth: (defaultDate)?defaultDate.format('M'):"",
			selectYear: (defaultDate)?defaultDate.format('Y'):""
		})
		var age = moment().diff(defaultDate, 'years');
	  this.setState({age: age});
	}

	componentWillMount() {
		let day = [], month = [], year = [];

		for (let i=1; i<=31; i++) {
			day.push(i);
		}

		let monthIndex = 1;
		
		for (const monthName of moment.localeData().months()) {
			month.push({
				text: monthName,
				value: monthIndex
			});
			monthIndex++;
		}
		
		const minYear =  1900;
		const maxYear =  moment().format('Y');
		for (let i=maxYear; i>=minYear; i--) {
			year.push({
				text: parseInt( i )+543,
				value: i
			});
		}

		this.setState({
			day: day,
			month: month,
			year: year
		});
	}

	changeDate(e, type) {
		this.setState({
			[type]: e.target.value
		});
		this.checkDate(e.target.value, type);
	}

	getDate(date) {
		if(moment(date).isValid()) {
			return moment(date).format('Y-MM-DD');
		} else {
			return undefined;
		}
	}

	checkDate(value, type) {
		let { selectDay, selectMonth, selectYear } = this.state;

		if (type === 'selectDay') {
			selectDay = value;
		} else if (type === 'selectMonth') {
			selectMonth = value;
		} else if (type === 'selectYear') {
			selectYear = value;
		}

		if (this.isSelectedAllDropdowns(selectDay, selectMonth, selectYear)) {
			const dateObject = {
				year :selectYear,
				month :selectMonth - 1,
				day :selectDay
			};
			this.props.dateChange(this.getDate(dateObject));
		} else {
			this.props.dateChange(undefined);
		}
	}

	isSelectedAllDropdowns(selectDay, selectMonth, selectYear) {
		if (selectDay === '' || selectMonth === '' || selectYear === '') {
			return false;
		}
		return ((selectDay) && (selectMonth) && (selectYear)) ;
	}

	render() {
		const dayElement = this.state.day.map((day, id) => {
			return <option value={ day } key={ id }>{ day }</option>;
		});
		const monthElement = this.state.month.map((month, id) => {
			return <option value={ month.value } key={ id }>{ month.text }</option>;
		});
		const yearElement = this.state.year.map((year, id) => {
			return <option value={ year.value } key={ id }>{ year.text }</option>;
		});

		return (
			<Translate>
			{({ translate }) => 
				<div>
					<select value={this.state.selectDay} onChange={(e) => this.changeDate(e, 'selectDay')}>
						<option value="">{translate("date")}</option>
						{ dayElement }
					</select>
					<select value={this.state.selectMonth} onChange={(e) => this.changeDate(e, 'selectMonth')}>
						<option value="">{translate("month")}</option>
						{ monthElement }
					</select>
					<select value={this.state.selectYear} onChange={(e) => this.changeDate(e, 'selectYear')}>
						<option value="">{translate("year")}</option>
						{ yearElement }
					</select>
					{this.state.age?<span>อายุ &nbsp; <strong>{this.state.age}</strong> &nbsp; ปี</span>:null}
				</div>
      }
			</Translate>
		);
	}
}