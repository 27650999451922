import React, { PureComponent } from "react"
// import { Translate } from "react-localize-redux";
import {
    BinocVision,
    BothEyes,
    FarRightVision,
    FarLeftVision,
    SnellenVision,
    StereoDepthVision,
    ColorVision,
    LateralVision,
    NearBothEyes,
    NearRightVision,
    NearLeftVision,
    NearLateral,
} from "./"
import { VerticalVision } from "./VerticalVision";

export class VisionTable extends PureComponent {
    binocVision;
    bothEyes;
    farRightEyes;
    farLeftEyes;
    snellen;
    stereoDepthVision;
    colorVision;
    verticalVision;
    lateralVision;
    nearBothEyes;
    nearRightVision;
    nearLeftVision;
    nearLateral;
    parentSetState = (any) => {};
    constructor(props, setState) {
        super(props)
        this.binocVision = props.template.find(x => x.handle === 'binoc')
        this.bothEyes = props.template.find(x => x.handle === 'far_both')
        this.farRightEyes = props.template.find(x => x.handle === 'far_rt')
        this.farLeftEyes = props.template.find(x => x.handle === 'far_lt')
        this.snellen = props.template.find(x => x.handle === 'far_snellen')
        this.stereoDepthVision = props.template.find(x => x.handle === 'far_stereo')
        this.colorVision = props.template.find(x => x.handle === 'far_color')
        this.verticalVision = props.template.find(x => x.handle === 'far_vertical')
        this.lateralVision = props.template.find(x => x.handle === 'far_lateral')
        this.nearBothEyes = props.template.find(x => x.handle === 'near_both')
        this.nearRightVision = props.template.find(x => x.handle === 'near_rt')
        this.nearLeftVision = props.template.find(x => x.handle === 'near_lt')
        this.nearLateral = props.template.find(x => x.handle === 'near_lateral')
        this.parentSetState = setState;
    }
    getLimit(template) {
        let limit = {}
        if(this.props.occ_limit) {
            limit = this.props.occ_limit.find(x=>x.handle===template.handle)
        }
        return limit
    }
    render() {
        return (
            <div className="oc-form-group-wrapper oc-form-table-wrapper">
                <table className="oc-form-table">
                    <tbody>
                        <tr>
                            <td rowSpan="11" className="oc-form-table-aside-top">
                                <span>FAR POINT(20 FT.)TEATS</span>
                            </td>
                        </tr>
                        <BinocVision template={this.binocVision} setState={this.parentSetState.bind(this)}></BinocVision>
                        <tr className="oc-form-table-section-header">
                            <td colSpan="2">Target</td>
                            <td>1</td>
                            <td>2</td>
                            <td>3</td>
                            <td>4</td>
                            <td>5</td>
                            <td>6</td>
                            <td>7</td>
                            <td>8</td>
                            <td>9</td>
                            <td>10</td>
                            <td>11</td>
                            <td>12</td>
                            <td>13</td>
                            <td>14</td>
                            <td></td>
                        </tr>
                        
                        <BothEyes template={this.bothEyes} limit={this.getLimit(this.bothEyes)} setState={this.parentSetState.bind(this)}></BothEyes>
                        <FarRightVision template={this.farRightEyes} limit={this.getLimit(this.farRightEyes)} setState={this.parentSetState.bind(this)}></FarRightVision>
                        <FarLeftVision template={this.farLeftEyes} limit={this.getLimit(this.farLeftEyes)} setState={this.parentSetState.bind(this)}></FarLeftVision>

                        <SnellenVision template={this.snellen} setState={this.parentSetState.bind(this)}></SnellenVision>

                        <StereoDepthVision template={this.stereoDepthVision} limit={this.getLimit(this.stereoDepthVision)} setState={this.parentSetState.bind(this)}></StereoDepthVision>
                        <ColorVision template={this.colorVision} setState={this.parentSetState.bind(this)}></ColorVision>
                        <VerticalVision template={this.verticalVision} limit={this.getLimit(this.verticalVision)} setState={this.parentSetState.bind(this)}></VerticalVision>
                        <LateralVision template={this.lateralVision} limit={this.getLimit(this.lateralVision)} setState={this.parentSetState.bind(this)}></LateralVision>
                        <tr>
                            <td rowSpan="6" className="oc-form-table-aside-bottom">
                                <span>NEAR POINT (14 IN.)</span>
                            </td>
                            <td colSpan="17" style={{padding: 0}}>
                                <hr />
                            </td>
                        </tr>
                        <tr className="oc-form-table-section-header">
                            <td colSpan="2">
                                Target
                            </td>
                            <td>1</td>
                            <td>2</td>
                            <td>3</td>
                            <td>4</td>
                            <td>5</td>
                            <td>6</td>
                            <td>7</td>
                            <td>8</td>
                            <td>9</td>
                            <td>10</td>
                            <td>11</td>
                            <td>12</td>
                            <td>13</td>
                            <td>14</td>
                            <td></td>
                        </tr>
                        <NearBothEyes template={this.nearBothEyes} limit={this.getLimit(this.nearBothEyes)} setState={this.parentSetState.bind(this)}></NearBothEyes>
                        <NearRightVision template={this.nearRightVision} limit={this.getLimit(this.nearRightVision)} setState={this.parentSetState.bind(this)}></NearRightVision>
                        <NearLeftVision template={this.nearLeftVision} limit={this.getLimit(this.nearLeftVision)} setState={this.parentSetState.bind(this)}></NearLeftVision>
                        <NearLateral template={this.nearLateral} limit={this.getLimit(this.nearLateral)} setState={this.parentSetState.bind(this)}></NearLateral>
                    </tbody>
                </table>
            </div>
        )
    }
}