import React, { Component } from "react";

class MiniClock extends Component {
	intervalID = 0;
	constructor(props) {
    super(props);
    
    this.state = {
	    current: null,
    };
    
    this.update = this.update.bind(this);
  }
  
  componentDidMount() {
	 	this.intervalID = setInterval(this.update, 60000)
	  this.update();
  }
  
  componentWillUnmount() {
	  clearInterval(this.intervalID);
  }
  
  update()
  {
  	var time = new Date();
		var current = (
    	("0" + time.getHours()).slice(-2)   + ":" + 
			("0" + time.getMinutes()).slice(-2)
		);
		this.setState({current:current})
  }
  
  render() { 
	  
		return (
			<time>{this.state.current}</time>
		);
  }
}

export default MiniClock;