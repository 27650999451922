import React from "react"
import { SelectBox } from "./helper";
import { Translate } from "react-localize-redux";

export class Occupation extends SelectBox {
	constructor(props) {
		super(props, props.setState)
	}
	// componentDidMount() {
	// 	this.setState({
	// 		value: this.props.template.data.value
	// 	})
	// }
	render() {
		// console.log(this.props.template)
		return (
			<Translate key={this.props.template.id}>
				{({ translate }) => 
					<div className="oc-form-group-wrapper">
						<h6>{this.props.template.name}</h6>
						<select className="form-control" name="occupation_group" onChange={(e) => this.onChangeHandler(e)} value={this.state.value}>
							<option value="">{translate('please_select_type')}</option>
							{this.props.template.choices?this.props.template.choices.map( (item, index) => {
								return (<option value={item.handle} key={`occopt_${item.id}`} >{item.display}</option>)
							}):null}
						</select>
						<br/>
					</div>
				}
			</Translate>
		)
	}
}