import React, { Component } from "react";
import moment from 'moment';

import Logout from '../../components/Logout';
import Loading from '../../components/Loading';

import authFetch from '../../services/authFetch';
import { Translate } from "react-localize-redux";

class ManagementPatientInfo extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			vn: this.props.match.params.vn,
			patient: {},
			packages: [],
			programs: [],
			add_on_labs: [],
			registered_at: null,
			specimens: [],
			add_on_price: 0
		};

		authFetch.get('visit/' + this.state.vn + '/overview', (result) => {
			//console.log(result)
			if (result.success) {
				var price = 0;
				if (result.add_on_labs && result.add_on_labs.length > 0) {
					result.add_on_labs.map((item, index) => {
						price += parseInt(item.price);
					})
				}

				if (result.packages && result.packages.length > 0) {
					result.packages.map((item, index) => {
						price += parseInt(item.price);
					})
				}

				result.add_on_price = price;

				this.setState({ ...this.state, ...result });
			}
			this.setState({ loading: false });
		});
	}

	render() {
		return (
			<Translate>
				{({ translate }) =>
					<div className="management-page page">
						<header className="page-header">
							<button className="link-page-back" onClick={() => this.props.history.push('/management')}>
								{translate("back")}
							</button>

							<h1>{translate("patient_information")}</h1>
							<Logout />
						</header>

						<main className="page-content">
							<div className="visit-overview-wrapper">
								<div className="visit-overview-content">
									<div className="visit-overview-info">

										<div className="visit-overview-row">
											<div>
												{translate("vn")}: <strong>{this.state.vn}</strong>
											</div>
											<div className="text-right">
												{translate("status")}:
												{(this.state.registered_at) ?
													<strong> {translate("registered")}</strong>
													:
													<strong> {translate("not_registered")}</strong>
												}
											</div>
										</div>
										<table className="visit-overview-table">
											<tbody>
												<tr>
													<th width="185">{translate("name")}</th>
													<td>{this.state.patient.fullname}</td>
												</tr>

												{(this.state.patient.cid) ?
													<tr>
														<th>{translate("thai_citizen_id")}</th>
														<td>{this.state.patient.cid}</td>
													</tr>
													: null}

												{(this.state.patient.other_id) ?
													<tr>
														<th>{translate("other_id")}</th>
														<td>{this.state.patient.other_id}</td>
													</tr>
													: null}
												<tr>
													<th>{translate("address")}</th>
													<td>{this.state.patient.address}</td>
												</tr>
												<tr>
													<th>{translate("employee_id")}</th>
													<td>{this.state.patient.eid}</td>
												</tr>
												<tr>
													<th>{translate("position")}</th>
													<td>{this.state.patient.position}</td>
												</tr>
												<tr>
													<th>{translate("department")}</th>
													<td>{this.state.patient.department}</td>
												</tr>
												<tr>
													<th>{translate("company_branch")}</th>
													<td>{this.state.patient.company_name}</td>
												</tr>
												<tr>
													<th>{translate("birthday")}</th>
													<td>{this.state.patient.dob}</td>
												</tr>
												<tr>
													<th>{translate("gender")}</th>
													<td>{this.state.patient.sex?translate(this.state.patient.sex):"-"}</td>
												</tr>
												<tr>
													<th>{translate("phone_number")}</th>
													<td>{this.state.patient.tel}</td>
												</tr>
											</tbody>
										</table>

										<h5>{translate('checkup')}</h5>

										<table className="visit-overview-table">
											<tbody>
												<tr>
													<th width="185">{translate("program")}</th>
													<td>
														{this.state.programs.map((item, index) => { return item.name }).join(', ')}
													</td>
												</tr>
											</tbody>
										</table>
										<table className="visit-overview-table">
											<tbody>
												<tr className="addition">
													<th width="185">{translate("package")}</th>
													<td>
														{this.state.packages && this.state.packages.length > 0 ? (
															<span>{this.state.packages.map((item, index) => { return item.name }).join(', ')}</span>
														) : (
															<span>-</span>
														)}
													</td>
												</tr>
												<tr className="addition">
													<th>{translate("addition_lab")}</th>
													<td>
														{this.state.add_on_labs && this.state.add_on_labs.length > 0 ? (
															<span>{this.state.add_on_labs.map((item, index) => { return item.name }).join(', ')}</span>
														) : (
															<span>-</span>
														)}
													</td>
												</tr>
												<tr className="addition">
													<th>{translate("addition_price")}</th>
													<td>
														<strong>{this.state.add_on_price.toLocaleString()}</strong> ฿
													</td>
												</tr>
											</tbody>
										</table>

										<p className="text-center">
											<button className="btn btn-round"
												onClick={() => { this.props.history.push('/register/form/' + this.state.vn, { backTo: '/management/patient/' + this.state.vn }); }}>{translate("edit_info_checkup")} <i className="fa fa-edit"></i></button>
										</p>

									</div>
									<div className="visit-overview-result">
										{this.state.visit ? (
											<div>
												<table className="visit-overview-table visit-overview-table-specimens">
													<thead>
														<tr>
															<th>{translate("data")}</th>
															<th>{translate("value")}</th>
														</tr>
													</thead>
													<tbody>
														<tr>
															<td>{translate('bp')}</td>
															<td>{this.state.visit.bp}</td>
														</tr>
														<tr>
															<td>{translate('weight')}</td>
															<td>{this.state.visit.wt}</td>
														</tr>
														<tr>
															<td>{translate('height')}</td>
															<td>{this.state.visit.ht}</td>
														</tr>
													</tbody>
												</table>
											</div>
										) : null}

										{this.state.specimens && this.state.specimens.length > 0 ? (
											<div>
												<strong>{translate('progress')}</strong>
												<table className="visit-overview-table visit-overview-table-specimens">
													<thead>
														<tr>
															<th>{translate("specimen")}</th>
															<th>{translate("status")}</th>
														</tr>
													</thead>
													<tbody>
														{this.state.specimens.map((item, index) => {
															return (
																<tr key={index}>
																	<td>{item.name}</td>
																	<td>
																		{item.received_at ? (
																			<span>
																				<i className="fa fa-check"></i>&nbsp;
																				{moment(item.received_at).format('HH:mm')}
																			</span>
																		) : (
																			<span>{translate('waiting_for_specimen')}</span>
																		)}
																	</td>
																</tr>
															)
														})}
													</tbody>
												</table>
											</div>
										) : (
											<h3>{translate('no_checkup_item')}</h3>
										)}

									</div>
									<div className="visit-overview-actions">
										<p>
											{translate("vn")}: <strong>{this.state.vn}</strong>
										</p>
										<p>
											{translate("status")}:
											{(this.state.registered_at) ?
												<span><strong> {translate("registered")}</strong><br />(<small>{this.state.registered_at}</small>)</span>
												:
												<strong> {translate("not_registered")}</strong>
											}
										</p>
										<p>
											<button className="btn btn-block btn-round"
												onClick={() => { this.props.history.push('/management/visit/specimens/' + this.state.vn, { backTo: '/management/patient/' + this.state.vn }); }}>{translate("count_specimen")} <i className="fa fa-vials"></i></button>
										</p>
										<p>
											<button className="btn btn-block btn-round"
												onClick={() => { this.props.history.push('/management/pe_print/' + this.state.vn, { backTo: '/management/patient/' + this.state.vn }); }}>{translate("print_pe")} <i className="fa fa-print"></i></button>
										</p>
										<p>
											<button className="btn btn-block btn-round"
												onClick={() => { this.props.history.push('/management/mc_print/' + this.state.vn, { backTo: '/management/patient/' + this.state.vn }); }}>{translate("print_medical_certificate")} <i className="fa fa-print"></i></button>
										</p>
										<p>
											<a
												href={'/management/visit/stickers/' + this.state.vn} className="btn btn-round btn-block">{translate("print_sticker")} <i className="fa fa-print"></i></a>
										</p>
										<p>
											<button className="btn btn-block btn-round"
												onClick={() => { this.props.history.push('/management/pe_form/' + this.state.vn, { backTo: '/management/patient/' + this.state.vn }); }}>{translate("pe_result")} <i className="fa fa-file-medical-alt"></i></button>
										</p>
									</div>
								</div>
							</div>
						</main>
						<Loading show={this.state.loading} />
					</div>
				}
			</Translate>
		);
	}
}

export default ManagementPatientInfo;
