import React, { Component } from "react";
import {
  Link,
} from "react-router-dom";

import Logout from '../../components/Logout';
import { Translate } from "react-localize-redux";

import ProgressOverview from '../../components/ProgressOverview';

class Management extends Component {
	constructor(props) {
    super(props);
    this.state = {};
  }
    
  componentDidMount() {
  }

  render() {
    return (
			<Translate>
			{({ translate }) => 
	      <div className="management-page page">
	      	<header className="page-header">
		      	<Link className="link-page-back" to={'/home'}>
	      			{translate("back")}
	      		</Link>
	      		
		      	<h1>{translate("management")}</h1>
		      	<Logout/>
	      	</header>
	      	
	      	<main className="page-content">
		      	<div className="page-content-main">
		      		<div className="page-content-padded">
			      		<ul className="select-management">
				      		<li>
						      	<Link to={'/management/patients'}>
					      			<i className="fa fa-user"></i> {translate("patient_information")}
					      		</Link>
					      	</li>
				      		{/*
					      	
					      	<li>
						      	<Link to={'/register/form'}>
					      			<i className="fa fa-user-plus"></i> {translate("add_patients")}
					      		</Link>
					      	</li>
					      	*/ }
					      	<li>
						      	<Link to={'/management/specimens'}>
					      			<i className="fa fa-vials"></i> {translate("count_specimens")}
					      		</Link>
					      	</li>
					      	<li>
						      	<Link to={'/management/pe_search'}>
					      			<i className="fa fa-file-medical-alt"></i> {translate("pe_result")}
					      		</Link>
					      	</li>
					      	<li>
						      	<Link to={'/management/specimen_sticker'}>
					      			<i className="fa fa-print"></i> พิมพ์สติ๊กเกอร์
					      		</Link>
					      	</li>
					      </ul>
		      		</div>
		      	</div>
		      	<aside className="page-content-aside"> 
			      	
		      		<div className="page-content-aside-header">
			      		<div className="page-content-aside-header-title">{translate("overview")}</div>
			      		<div className="page-content-aside-header-button">
			      			<Link to={'/management/specimens_all'}>
				      			<img src={require('assets/images/icon-expand.svg')} width="30" />
			      			</Link>
			      		</div>
			      	</div>
			      	
		      		<div className="page-content-aside-content">
			      		<ProgressOverview history={this.props.history}/>
		      		</div>
		      	</aside>
		      </main>
	      </div>
      }
			</Translate>
    );
  }
}

export default Management;
