import React, { Component } from "react";

import moment from 'moment';

import Logout from '../../components/Logout';
import Loading from '../../components/Loading';

import authFetch from '../../services/authFetch';
import { Translate } from "react-localize-redux";

class ManagementVisitSpecimen extends Component {
	constructor(props) {
    super(props);
    this.state = {
	    loading:true,
	    vn: this.props.match.params.vn,
	    specimens: [],
	    patient:""
    };
    
    this.doUpdate = this.doUpdate.bind(this);
    this.goBack = this.goBack.bind(this);
  }
    
  componentDidMount() {
	  
	  
  	authFetch.get('visit/'+this.state.vn+'/specimens',(result)=>{
		  if(result.success){
			  this.setState({loading:false,specimens:result.specimens,patient:result.patient});
	    }
	  });
  }
  
  doUpdate(specimen_id,received)
  {
	  this.setState({loading:true});
	  var data = {
		  //visit_id: this.state.vn,
		  specimen_visit_id: specimen_id,
		  received: received
	  }
		  
	  authFetch.put('visit/specimen',data,(result)=>{
		  if(result.success){
			  var specimens = [];
				specimens =  this.state.specimens.map((item,index)=>{
					if(specimen_id===item.id)
					{						
						if(received){
							item.received_at = moment().format('Y-M-D HH:mm');
						} else {
							item.received_at = null;
						}
					}
					return item;
				});
			  this.setState({specimens:specimens})
	    }
			this.setState({loading:false})
	  });
  }

	goBack()
	{
		var backTo = '/management/specimens_all';
		if(this.props.location.state && this.props.location.state.backTo) 
		{
			backTo = this.props.location.state.backTo;
		} 
		this.props.history.push(backTo);
	}
	
  render() {
	  
    return (
			<Translate>
			{({ translate }) => 
	      <div className="management-page page">
	      	<header className="page-header">
		      	<button className="link-page-back"  onClick={()=>this.goBack()}>
	      			{translate("back")}
	      		</button>
		      	<h1>{translate("count_specimen")}</h1>
		      	<Logout/>
	      	</header>
	      	
	      	<main className="page-content">
		      	<div className="page-content-main">
			      	<h5>VN: {this.state.vn} {this.state.patient}</h5>
		      		{(this.state.specimens && this.state.specimens.length>0)?
			      		(
				      		<table className="light-table">
							      	<thead>
								      	<tr>
									      	<th>{translate("specimen")}</th>
									      	<th className="text-center" colSpan="2">{translate("status")}</th>
								      	</tr>
							      	</thead>
							      	<tbody>
								      	{this.state.specimens.map((item,index)=>{
									      	return (
										      	<tr key={index}>
											      	<td className="text-center">{item.name}</td>
											      	<td className="text-center" width="150">
												      	{item.received_at?(
													      	<span>
														      	{moment(item.received_at).format('HH:mm')}
													      	</span>
													      ):(
													      	<span>{translate('waiting_for_specimen')}</span>
													      )}
											      	</td>
											      	<td className="text-center"  width="70">
												      	{item.received_at?(
								      					<button className="mini-check" onClick={()=>this.doUpdate(item.id, 0)}>
									      					<i className="fa fa-check"></i>
									      				</button>
									      				):(
								      					<button className="mini-check" onClick={()=>this.doUpdate(item.id, 1)}>
									      					<i></i>
									      				</button>
									      				)}
											      	</td>
										      	</tr>
									      	)
								      	})}
							      	</tbody>
						      	</table>
			      		) : (null)
							}
	      		</div>
						<Loading show={this.state.loading} />
		      </main>
	      </div>
      }
			</Translate>
    );
  }
}

export default ManagementVisitSpecimen;
