import React, { Component } from "react";
import {
	Link,
} from "react-router-dom";

import Logout from '../../components/Logout';
import Loading from '../../components/Loading';
import Auth from '../../helpers/Auth';
import authFetch from '../../services/authFetch';
import { Translate } from "react-localize-redux";

class ManagementOtherList extends Component {
	constructor(props) {
		super(props);

		this.state = {
			keyword: this.props.match.params.vn,
			visit: null,
			loading: true
		};

		this.loadOrders = this.loadOrders.bind(this);
		this.goBack = this.goBack.bind(this);
	}

	componentWillUnmount() {
		document.removeEventListener("keydown", this.ctrlSubmit, false);
	}

	ctrlSubmit(e) {
		if (e.ctrlKey && e.keyCode == 13) {
			// Ctrl-Enter pressed
			this.doSubmit(e);
		}
		return;
	}

	componentDidMount() {
		document.addEventListener("keydown", this.ctrlSubmit, false);
		// authFetch.get('pe', (result) => {
		// 	//console.log(result)
		// 	if (result.success) {
		// 		this.loadVisit(result.pe_templates, result.personal_templates, result.practitioners);
		// 	}
		// });
		this.loadOrders();
	}

	loadOrders() {
		authFetch.get('other/search?keyword=' + this.state.keyword, (result) => {
			console.log(result)
			if (result.success && result.visit) {
				this.setState({ visit: result.visit });
			} else {
				this.setState({ visit: null });
			}
			this.setState({ loading: false });
		});
	}

	goBack() {
		var backTo = '/management/other_search';
		if (this.props.location.state && this.props.location.state.backTo) {
			backTo = this.props.location.state.backTo;
		}
		this.props.history.push(backTo);
	}

	render() {
		return (
			<Translate>
				{({ translate }) =>
					<div className="management-page page">
						<header className="page-header">
							<button className="link-page-back" onClick={this.goBack}>
								{translate("back")}
							</button>
							<h1>{translate("other_result")}</h1>
							<Logout />
						</header>

						<main className="page-content">
							{this.state.visit ? (
								<div className="other-list">
									<h3>{this.state.visit && this.state.visit.fullname}</h3>
									{this.state.visit.order && Array.isArray(this.state.visit.order) && this.state.visit.order.length > 0?
									<ul className="other-order-list">
										{this.state.visit.order.map((item, index) => {
											return (
												<li key={'order-'+index}>
													<Link to={'/management/other_form/'+this.state.visit.vn+'/'+item.id}>{item.name}</Link>
												</li>
											)
										})}
									</ul>
									:
										<div>
											<h4>{translate("no_other_order")}</h4>
										</div>
									}
								</div>
							) : (null)}
							<Loading show={this.state.loading} />
						</main>
					</div>
				}
			</Translate>
		);
	}
}

export default ManagementOtherList;