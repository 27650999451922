import React, { Component } from "react";

class FlashMessage extends Component {
	constructor(props) {
    super(props);
    
    this.state = {};
  }
  
  render() {
	  var typeClass = "flash-message-"+this.props.options.type
	  
	  if(this.props.show)typeClass+=" flash-message-show";
	  else typeClass+=" flash-message-hide";
	  
	  var messagesLi = this.props.options.messages.map(function(item, index){
      return <li key={ index }>{item}</li>;
    });
	  
    return (
      <div className={`flash-message ${typeClass}`}>
      	<ul>
      	{messagesLi}
      	</ul>
      </div>
    );
  }
}

export default FlashMessage;
