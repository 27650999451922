import { Component } from "react";

export class Nothing {
	render() {
		return [null]
	}
}
class Input extends Component {
	parentSetState = () => {}
	constructor(props, setState) {
		super(props)
		this.parentSetState = setState
	}
	onChangeHandler(e) {
		const newState = {}
		if (e.target.type === 'text') {
			newState['note'] = e.target.value
		}
		if (e.target.type === 'radio') {
			newState['value'] = e.target.value
		}
		if (e.target.name === 'vhx-5') {
			const newValue = `${e.target.value} ${this.state.note}`
			const indexOf = this.state.value.indexOf(newValue)
			if (indexOf === -1) {
				this.state.value.push(newValue)
			} else {
				this.state.value.splice(indexOf, 1)
			}
		} else if (e.target.type === 'checkbox') {
			const newValue = e.target.value
			const indexOf = this.state.value.indexOf(newValue)
			if (indexOf === -1) {
				this.state.value.push(newValue)
			} else {
				this.state.value.splice(indexOf, 1)
			}
		}
		this.setState(newState, () => this.parentSetState(this.props, this.state))
	}
	onClickHandler(e) {
		const targetValue = e.target.value
		if (this.state.value !== targetValue) {
			return
		}
		this.setState({
			value: 'empty'
		}, () => this.parentSetState(this.props, this.state))
	}
}

export class SelectBox extends Input {
	state = {
		value: ''
	}
	componentDidMount() {
        const newState = {}
        newState['value'] = this.props.template.value
        this.setState(newState)
    }
	onChangeHandler(e) {
		const state = {'value':e.target.value}
		this.setState(state, () => this.parentSetState(this.props, this.state))
	}
}
export class TextBox extends Input {
	state = {
		value: ''
	}
	componentDidMount() {
        const newState = {}
        newState['value'] = this.props.template.value
        this.setState(newState)
    }
	onChangeHandler(e) {
		const state = {}
		if (e.target.type === 'text') {
			state['value'] = e.target.value
		}
		this.setState(state, () => this.parentSetState(this.props, this.state))
	}
}
export class CheckBox extends Input {
	state = {
		value: [],
		note: ''
	}
}
export class RadioBox extends Input {

	state = {
		value: '',
		note: ''
	}

	componentDidMount() {
		let value = ''
		let note = ''
		if (typeof this.props.template !== 'undefined') {
			value = this.props.template.value
			note = this.props.template.note
		} 
		if (typeof this.props.item !== 'undefined') {
			value = this.props.item.value
			note = this.props.item.note
		}
		this.setState({
			value,
			note
		})
	}

	get value() {
		return this.state.value
	}

	get note() {
		return this.state.note
	}
}