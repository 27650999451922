import React, { Component } from "react";
import {
  Redirect,
} from "react-router-dom";
import Auth from '../helpers/Auth';
import MiniLanguageToggle from './MiniLanguageToggle';
import MiniClock from './MiniClock';
import MiniSetting from './MiniSetting';
import SwitchUnit from './SwitchUnit';

import { Translate } from "react-localize-redux";

class Logout extends Component {
	constructor(props) {
    super(props);
    this.state = {
	    redirect: false,
	    hideHome: this.props.hideHome,
		unit_name: null,
		user_fullname: null,
		user_lid: null
    }
    
    this.logout = this.logout.bind(this);
    this.gohome = this.gohome.bind(this);
  }

  componentDidMount() {
    this.setState({
      unit_name: localStorage.getItem('unit_name'),
	  user_fullname: localStorage.getItem('user_fullname'),
	  user_lid: localStorage.getItem('user_lid')
    })
  }
  
  logout() {
  	Auth.logout(()=>{
		  this.setState({ redirect: '/login' });
	  });
  }
  
  gohome() {
	  this.setState({ redirect: '/home' });
  }
  
  render() {
    if (this.state.redirect) return <Redirect to={this.state.redirect} />;

	  return (
	  <Translate>
			{({ translate }) => 
				<div className="logout-wrapper">
					<div className="menu-topbar">
						{!this.state.hideHome?(<button onClick={this.gohome}><i className="fa fa-home"></i></button>):(null)}&nbsp;
						<MiniClock/>
						<MiniSetting/>
						<SwitchUnit/>
						<MiniLanguageToggle/>
					</div>
					<div className="menu-topbar">
						<small>&nbsp;{this.state.user_fullname}</small>
						<button onClick={this.logout}><i className="fa fa-sign-out-alt"></i></button>
					</div>
				</div>
			}
		</Translate>
		)
  }
}

export default Logout;