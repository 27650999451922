import React, { Component } from "react";
import { Translate } from "react-localize-redux";

import authFetch from '../services/authFetch';
class ProgramSelect extends Component {
	constructor(props) {
    super(props);
    
    this.state = {
	    show_modal: false,
	    programs: [],
	    selectedPrograms: []
    };
    
    this.saveChange = this.saveChange.bind(this);
    this.showModel = this.showModel.bind(this);
    this.hideModel = this.hideModel.bind(this);
    this.toggleProgram = this.toggleProgram.bind(this);
  }
  
  componentDidMount() {
	  var programs = [].concat( this.props.programs );
	  this.setState({selectedPrograms:programs})
  }
  
  toggleProgram(id) {
	  var selected = this.state.selectedPrograms;
	  var index = selected.indexOf(id);
	  if( index !== -1 ){
		  //already in, remove
		  selected.splice(index, 1);
	  } else {
		  //not in, add
		  selected.push(id);
	  }
	  this.setState({selectedPrograms:selected});
  }
  
  showModel()
  {
	  var programs = [].concat( this.props.programs );
	  this.setState({show_modal:true,selectedPrograms:programs})
  }
  
  hideModel()
  {
	  this.setState({show_modal:false,selectedPrograms:[]})
  }
  
  saveChange()
  {
	  this.props.update(this.state.selectedPrograms);
	  this.hideModel();
  }
  
  render() {
		return (
			<Translate>
			{({ translate }) => 
				<div className="select-program">
					<div className="select-program-display" onClick={this.showModel}>
					{this.props.programs.length===0 ? (
						<div>
			  			{translate("no_checkup_program")}
			  		</div>
			  	):(
			  		<div>
			  			{this.props.all_programs.map((item, index)=> {return ( this.props.programs.includes(item.id) )?item.name:false;}).filter(el => el).join(", ")}
			  		</div>
			  	)}
					</div>
					<div className="select-program-button" onClick={this.showModel}>					
						<button className="btn-icon" type="button" >
							<img src={require('assets/images/icon-edit.svg')} />
						</button>
					</div>
		  		{this.state.show_modal ? (
			  		<div className="modal">
					  	<div className="modal-body-program">
					  		<div className="modal-body-program-header">
					  			<div>{translate("program")}</div>
					  			<div>{translate("condition")}</div>
					  			<div>{translate("lab")}</div>
					  		</div>
					  		<div className="modal-body-program-content">
					  			<table className="modal-body-program-table">
					  				<tbody>
						  				{this.props.all_programs.map(function(item, index){
												return (<tr key={ index } onClick={()=>this.toggleProgram(item.id)}>
				      						<td width="20%" className="text-left">
					      						{(this.state.selectedPrograms.includes(item.id))?
					      							<i className="fa fa-check-square"></i>
					      							:
					      							<i className="far fa-square"></i>
					      						}
						  							{item.name}
						  						</td>
						  						<td>
							  						{(item.from_age || item.to_age)?<span> อายุ </span>:(null)}
							  						{(item.from_age)?<span>{item.from_age}</span>:(null)}
							  						{(item.from_age && item.to_age)?<span> - </span>:(null)}
							  						{(item.to_age)?<span>{item.to_age}</span>:(null)}
							  						&nbsp; {(item.sex)?<span>{translate(item.sex)}</span>:(null)}
						  						</td>
						  						<td width="60%" className="text-left">
							  						{item.labs.map(function(elem){ return elem.name;}).join(", ")}
							  					</td>
				      					</tr>)
									    }.bind(this)
									  )}
					  				</tbody>
					  			</table>
					  		</div>
					  		<div className="modal-body-program-footer">
					  			<div className="modal-body-program-footer-info">
					  				<strong>{translate("name")}</strong> {this.props.patient.fullname}
										<br/>
					  				<strong>{translate("age")}</strong> {this.props.patient.age}
							  		<div className="spacer"/>
										<strong>{translate("gender")}</strong> {(this.props.patient.sex)?<span>{translate(this.props.patient.sex)}</span>:(null)}
					  			</div>
					  			<div className="modal-body-program-footer-package">
						  			<div>
										{this.state.selectedPrograms.length>0?(<strong>{translate("selected_program")}:&nbsp;</strong>):''}
					  				{this.props.all_programs.map((item, index)=> {return ( this.state.selectedPrograms.includes(item.id) )?item.name:false;}).filter(el => el).join(", ")}
					  				</div>
					  			</div>
					  			<div className="modal-body-program-footer-button">
							  		<button className="btn-round"  onClick={this.hideModel}>{translate("cancel")}</button>
							  		<button className="btn-primary btn-round"  onClick={this.saveChange}>{translate("confirm_program_select")}</button>
						  		</div>
					  		</div>
					  	</div>
					  </div>
			  	):(null)}
				</div>
      }
			</Translate>
		);
  }
}

export default ProgramSelect;