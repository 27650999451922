import React, { Component } from "react";

import Logout from '../components/Logout';
import InlineLoading from '../components/InlineLoading';

import authFetch from '../services/authFetch';
import { Translate } from "react-localize-redux";

class PatientSearch extends Component {
	constructor(props) {
    super(props);
    this.state = {
	    search_results: null,
	    keyword: this.props.keyword,
    };
    this.doSearch = this.doSearch.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleKeywordChange = this.handleKeywordChange.bind(this);
  }
    
  componentDidMount() {
	  this.doSearch();
  }
  
  handleKeywordChange(event){
	  var target = event.target;
	  this.setState({keyword:target.value});
  }
  
  handleSubmit(e) {
	  e.preventDefault();
	  this.doSearch();
	}
	
	doSearch() {
    this.setState({search_results:null});
	  authFetch.get('visits/search?keyword='+this.state.keyword,(result)=>{
		  if(result.success){
		    this.setState({search_results:result.visits});
	    }
	  });
	}

  render() {
    return (
			<Translate>
			{({ translate }) => 
	      <div className="search-page page">
	      	<header className="page-header">
		      	<button className="link-page-back" onClick={()=>this.props.back()}>{translate("back")}</button>
			      <div className="header-form">
			      	<h1>{translate("search")} :</h1>
			      	<form className="search-form" onSubmit={(e)=>this.handleSubmit(e)}>
			      		<input type="text" className="form-control" placeholder={translate("search")} value={this.state.keyword} onChange={this.handleKeywordChange} autoFocus />
	      				<button type="submit" className="search-form-btn"><i className="fa fa-search"></i></button>
			      	</form>
		      	</div>
		      	<Logout/>
	      	</header>
	      	
	      	<main className="page-content">
		      	<div className="page-content-main">
		      		{this.state.search_results!==null  ? (
		      			<table className="light-table">
			      			<thead>
		      					<tr>
		      						<th className="text-center">{translate("name")}</th>
		      						<th className="text-center">{translate("thai_citizen_id")}</th>
		      						<th className="text-center">{translate("employee_id")}</th>
		      						<th className="text-center">VN</th>
		      						<th className="text-center" width="20"></th>
		      					</tr>
		      				</thead>
		      				<tbody>
			      				{this.state.search_results.map((item, index)=>{
											return (<tr key={ index } onClick={()=>{this.props.select(item.vn)}}>
			      						<td className="text-center">{item.patient.fullname}</td>
			      						<td className="text-center">{item.patient.cid}</td>
			      						<td className="text-center">{item.patient.eid}</td>
			      						<td className="text-center">{item.vn}</td>
			      						<td className="text-center">
				      						<i className="fas fa-arrow-circle-right"></i>
				      					</td>
			      					</tr>)
								    })}
		      				</tbody>
			      		</table>
					  	):(
					  		<InlineLoading show={true}/>
					  	)}
		      	</div>
		      </main>
	      </div>
      }
			</Translate>
    );
  }
}

export default PatientSearch;