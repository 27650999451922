import React, { Component } from "react";

class Modal extends Component {
	constructor(props) {
    super(props);
  }
  
  render() {
	  if(this.props.show){		  
		  return <div className="modal">
		  	<div className="modal-body">
			  	<div className="modal-header">{this.props.title}</div>
			  	<div className="modal-content">{this.props.children}</div>
		  	</div>
		  </div>
	  } else {
		  return null;
	  }
  }
}

export default Modal;