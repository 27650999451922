import React, { Component } from "react";
import {
	Redirect,
} from "react-router-dom";
import { Translate } from "react-localize-redux";

import Auth from '../../helpers/Auth';
import Loading from '../../components/Loading';
import LanguageToggle from '../../components/LanguageToggle';

import MainLogo from '../../components/MainLogo';
import FlashMessage from '../../components/FlashMessage';

import MiniLanguageToggle from '../../components/MiniLanguageToggle';
import MiniClock from '../../components/MiniClock';

import authFetch from '../../services/authFetch';

class Login extends Component {
	constructor(props) {
		super(props);
		this.state = {
			username: "",
			password: "",
			unit_id: "",
			unit_name: "",
			units: [],
			errors: [],
			loading: true,
			redirectToReferrer: false
		};

		this.handleInputChange = this.handleInputChange.bind(this);
		this.handleUnitChange = this.handleUnitChange.bind(this);
		this.login = this.login.bind(this);
		this.closeApp = this.closeApp.bind(this);
	}

	componentDidMount() {
		authFetch.get('units', (data) => {
			if (data.success) {
				this.setState({ 'loading': false, 'units': data.units });
			} else {
				this.setState({ 'loading': false })
			}
		});

		if (Auth.isAuthenticated) this.setState({ 'redirectToReferrer': true });
	}

	handleInputChange(event) {
		const target = event.target;
		const value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;

		this.setState({
			errors: [],
			[name]: value
		});
  }
  
	handleUnitChange(event) {
    const target = event.target;
		const value = target.value;
		const name = target.name;

		this.setState({
			errors: [],
      [name]: value,
      unit_name: target.options[target.selectedIndex].text
		});
	}

	closeApp() {
		window.close();
	}

	login(event) {
		event.preventDefault();
		this.setState({ 'loading': true });
		let credentials = {
			'username': this.state.username,
			'password': this.state.password,
			'unit_id': this.state.unit_id,
			'unit_name': this.state.unit_name
		};

		Auth.authenticate(credentials, (result, messages) => {
			if (result) {
				this.setState({ redirectToReferrer: true });
			} else {
				this.setState({ errors: messages });
			}

			this.setState({ 'loading': false });
		});
	}

	render() {
		let { from } = this.props.location.state || { from: { pathname: "/home" } };
		let { redirectToReferrer } = this.state;

		if (redirectToReferrer) return <Redirect to={from} />;

		if(this.state.units && this.state.units.length>0) {
			var unitOption = this.state.units.map(function (unit, index) {
				return <option value={unit.id} key={index}>{unit.job_name} {unit.location}</option>;
			});
		}

		return (
			<Translate>
				{({ translate }) =>
					<div className="login-page">

						<MainLogo />

						<div className="logout-wrapper">
							<div className="menu-topbar">
								<MiniClock />

								<MiniLanguageToggle />
							</div>
							<div className="menu-topbar">
								<button onClick={this.closeApp}><i className="fa fa-times"></i></button>
							</div>
						</div>

						<form className="login-form" onSubmit={this.login}>

							<div className="login-form-row text-center">
								<h1>{translate("login")}</h1>
							</div>

							<FlashMessage show={this.state.errors.length > 0 ? true : false} options={{ type: 'alert', messages: this.state.errors }} />

							<div className="login-form-row">
								<input type="text" className="form-control" placeholder={translate("username")}
									name="username" value={this.state.username} onChange={this.handleInputChange} required autoComplete="off" autoFocus />
							</div>
							<div className="login-form-row">
								<input type="password" className="form-control" placeholder={translate("password")}
									name="password" value={this.state.password} onChange={this.handleInputChange} required autoComplete="off" />
							</div>
							<div className="login-form-row">
								<select className="form-control" name="unit_id"
									value={this.state.unit_id} onChange={this.handleUnitChange} required>
									<option value="">{translate("unit")}</option>
									{unitOption}
								</select>
							</div>
							<div className="login-form-bottom text-center">
								<button className="btn btn-black" type="submit">{translate("login")}</button>
							</div>
						</form>
						<Loading show={this.state.loading} />
					</div>
				}
			</Translate>
		);
	}
}

export default Login;
