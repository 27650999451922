import Auth from '../helpers/Auth';

const authFetch = {
	inited: false,
	prefix: process.env.REACT_APP_SERVER_URL + '/api/v1/',
	headers: {},
	init() {
		//Auth.init();
		this.headers = {
			'Accept': 'application/json',
			'Content-Type': 'application/json'
		}
		if (Auth.isAuthenticated) {
			let authen_headers = { 'Authorization': 'Bearer ' + Auth.token, 'Checkmate-Unit': Auth.unit_id };
			this.headers = { ...this.headers, ...authen_headers }
		}
		this.inited = true;
	},
	get(url, cb) {
		this.init();
		console.log(url,'get')
		fetch(this.prefix + url, {
			method: 'GET',
			headers: this.headers
		})
		.then(checkError)
		.then((data) => data.json()).then((resp) => {
			console.log(url,'then')
			cb(resp);
		}).catch((error) => {
			console.log(error)
			cb({ success: 0 });
			this.handleErrors(error);
		});
	},
	post(url, body, cb) {
		this.init();
		fetch(this.prefix + url, {
			method: 'POST',
			headers: this.headers,
			body: JSON.stringify(body)
		})
			.then(checkError)
			.then((data) => data.json()).then((resp) => {
				cb(resp);
			}).catch((error) => {
				cb({ success: 0 });
				this.handleErrors(error);
			});
	},
	blobdownload(url, cb) {
		this.init();
		console.log('download');
		let headers = { ...this.headers, ...{ 'Accept': 'application/pdf', 'responseType': 'blob' } }
		fetch(this.prefix + url, {
			method: 'GET',
			headers: headers
		})
		.then(checkError)
		.then(response => response.blob())
		.then(function(myBlob) {
			//var objectURL = URL.createObjectURL(myBlob);
			cb(myBlob);
		});
		/*
		.then((resp) => {
			cb(resp.blob());
		}).catch((error) => {
			//console.log(error)
			cb({ success: 0 });
			this.handleErrors(error);
		});
		*/
	},
	put(url, body, cb) {
		this.init();
		fetch(this.prefix + url, {
			method: 'PUT',
			headers: this.headers,
			body: JSON.stringify(body)
		})
			.then(checkError)
			.then((data) => data.json()).then((resp) => {
				cb(resp);
			}).catch((error) => {
				console.log('error');
				console.log(error)
				cb({ success: 0 });
				this.handleErrors(error);
			});
	},
	handleErrors(response) {
		//console.log('handleerrors')
		if (!response.ok) {
			//alert('Error connecting to server');
			//throw Error(response.statusText);
		}
		return response;
	}
};

function checkError(res) {
	if (res.status === 200) {
		//ok
		return res;
	} else if (res.status === 401) {
		//unauthorize
		//console.log('check: 401')
		Auth.logout();
		throw new Error('Unauthorize');
	} else {
		//console.log('check: server error')
		//error
		throw new Error('Server error');
	}
}
export default authFetch;

