import React from "react";
import { withLocalize } from "react-localize-redux";

const LanguageToggle = ({ languages, activeLanguage, setActiveLanguage }) => (
  <span>
    {languages.map(lang => {
	    if(lang!==activeLanguage){		    
	      return (<button key={lang.code} onClick={() => setActiveLanguage(lang.code)}>
	        <i className="fa fa-globe-americas"></i>
	      </button>)
	    }
    })}
  </span>
);

export default withLocalize(LanguageToggle);