import React, { Component } from "react";

class MiniLoading extends Component {
	constructor(props) {
    super(props);
  }
  
  render() {
	  let defaultProps = {
	    color: "#ffffff",
	    height:20,
	    width: 20
	  };
	  
	  const Oval = svg => (
		  <svg
		    width={svg.width}
		    height={svg.height}
		    viewBox="0 0 38 38"
		    xmlns="http://www.w3.org/2000/svg"
		    stroke={svg.color}
		    aria-label={svg.label}
		  >
		    <g fill="none" fillRule="evenodd">
		      <g transform="translate(1 1)" strokeWidth="2">
		        <circle strokeOpacity=".6" cx="18" cy="18" r="18" />
		        <path d="M36 18c0-9.94-8.06-18-18-18">
		          <animateTransform
		            attributeName="transform"
		            type="rotate"
		            from="0 18 18"
		            to="360 18 18"
		            dur="1s"
		            repeatCount="indefinite"
		          />
		        </path>
		      </g>
		    </g>
		  </svg>
		);
	  
	  if(this.props.color)defaultProps.color=this.props.color;
	  if(this.props.show){		  
      let pops = {...defaultProps,...this.props};
		  return <div className="inline-loading">
		  	<Oval {...pops}/>
		  </div>
	  } else {
		  return null;
	  }
  }
}

export default MiniLoading;