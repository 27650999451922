import React, { Component } from "react";


import PatientSearch from '../../components/PatientSearch';

import { Translate } from "react-localize-redux";

class RegisterSearch extends Component {
	constructor(props) {
    super(props);
    this.state = {};
    this.searchSelect = this.searchSelect.bind(this);
  }
	
	searchSelect(vn)
	{
		this.props.history.push('/register/form/'+vn)
	}

  render() {
    return (
			<Translate>
			{({ translate }) => 
				<PatientSearch back={()=>this.props.history.push('/register')} select={this.searchSelect} keyword={this.props.match.params.keyword} />
      }
			</Translate>
    );
  }
}

export default RegisterSearch;
