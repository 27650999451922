import React from "react"
import { RadioBox } from "../helper";
import { Translate } from "react-localize-redux";

export class ProtectorUsed extends RadioBox {
	constructor(props) {
        super(props, props.setState)
	}
	render() {
		return (
			<Translate key={this.props.template.id}>
				{({ translate }) => 
					<div className="oc-form-group-wrapper">
						<h6>{ this.props.template.name }</h6>
                        <ul>
                            <li>
                                <label
                                    className={`
                                        oc-form-label
                                        ${this.state.value === 'ไม่ใช้' ? 'active': ''}
                                    `.trim()}
                                >
                                    {translate('form_audio_protector_used_never')}
                                    <input
                                        name={`${this.props.template.id}`}
                                        type="radio"
                                        value="ไม่ใช้"
                                        checked={this.state.value === 'ไม่ใช้'}
                                        onChange={(e) => this.onChangeHandler(e)}
                                    />
                                </label>
                            </li>
                            <li>
                                <label
                                    className={`
                                        oc-form-label
                                        ${this.state.value === 'ใช้ตลอดเวลา' ? 'active': ''}
                                    `.trim()}
                                >
                                    {translate('form_audio_protector_used_always')}
                                    <input
                                        name={`${this.props.template.id}`}
                                        type="radio"
                                        value="ใช้ตลอดเวลา"
                                        checked={this.state.value === 'ใช้ตลอดเวลา'}
                                        onChange={(e) => this.onChangeHandler(e)}
                                    />
                                </label>
                            </li>
                            <li>
                                <label
                                    className={`
                                        oc-form-label
                                        ${this.state.value === 'ใช้บางครั้ง' ? 'active': ''}
                                    `.trim()}
                                >
                                    {translate('form_audio_protector_used_sometime')}
                                    <input
                                        name={`${this.props.template.id}`}
                                        type="radio"
                                        value="ใช้บางครั้ง"
                                        checked={this.state.value === 'ใช้บางครั้ง'}
                                        onChange={(e) => this.onChangeHandler(e)}
                                    />
                                </label>
                            </li>
                        </ul>
					</div>
				}
			</Translate>
		)
	}
}