import React from "react"
import { Translate } from "react-localize-redux";
import { CheckBox } from "./helper";

export class PerimeterScore extends CheckBox {
    state = {
        peri_rt_85: false,
        peri_rt_70: false,
        peri_rt_55: false,
        peri_rt_45: false,
        peri_rt_total: '',
        peri_lt_85: false,
        peri_lt_70: false,
        peri_lt_55: false,
        peri_lt_45: false,
        peri_lt_total: '',
        peri_both_total: '',
    }
    constructor(props) {
        super(props, props.setState)
    }
    componentDidMount() {
        const newState = {}
        this.props.template.data.forEach(x => {
            // newState[x.handle] = x.value === 'true' ? true : false
            if (x.value === '1') {
                newState[x.handle] = true
            } else if (x.value === '0') {
                newState[x.handle] = false
            } else {
                newState[x.handle] = x.value === 'empty' ? '' : x.value
            }
            
        })
        this.setState(newState)
    }
    onChangeHandler(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        }, () => this.parentSetState(this.props, this.state));
    }
	render() {
		return (
			<Translate key={this.props.template.id}>
                {({ translate }) => 
                    <div className="oc-form-group-wrapper oc-form-perimeter-score-wrapper">
                        <table>
                            <tbody>
                                <tr>
                                    <td rowSpan="3">13. {this.props.template.name}</td>
                                </tr>
                                <tr>
                                    <td style={{
                                        width: 130
                                    }}>
                                        <strong>
                                            <u>
                                            {translate("form_vision_perimeter_right_temporal")}
                                            </u>
                                        </strong>
                                    </td>
                                    <td
                                        className="text-center"
                                        style={{
                                            width: 50
                                        }}
                                    >
                                        <label
                                            className={this.state.peri_rt_85 ? 'active': ''}
                                        >
                                            {/* <i className="fas fa-check"></i> */}
                                            <input
                                                name="peri_rt_85"
                                                type="checkbox"
                                                checked={this.state.peri_rt_85}
                                                onChange={this.onChangeHandler.bind(this)}
                                            />
                                            <span>85°</span>
                                        </label> 
                                    </td>
                                    <td
                                        className="text-center"
                                        style={{
                                            width: 50
                                        }}
                                    >
                                        <label
                                            className={this.state.peri_rt_70 ? 'active': ''}
                                        >
                                            <input
                                                name="peri_rt_70"
                                                type="checkbox"
                                                checked={this.state.peri_rt_70}
                                                onChange={(e) => this.onChangeHandler(e)}
                                            />
                                                70°
                                        </label> 
                                    </td>
                                    <td
                                        className="text-center"
                                        style={{
                                            width: 50
                                        }}
                                    >
                                        <label
                                            className={this.state.peri_rt_55 ? 'active': ''}
                                        >
                                            <input
                                                name="peri_rt_55"
                                                type="checkbox"
                                                checked={this.state.peri_rt_55}
                                                onChange={(e) => this.onChangeHandler(e)}
                                            />
                                            55°
                                        </label>
                                    </td>
                                    <td
                                        className="text-center"
                                        style={{
                                            width: 100
                                        }}
                                    >
                                        <label
                                            className={this.state.peri_rt_45 ? 'active': ''}
                                        >
                                            <input
                                                name="peri_rt_45"
                                                type="checkbox"
                                                checked={this.state.peri_rt_45}
                                                onChange={(e) => this.onChangeHandler(e)}
                                            />
                                            Nasal 45°
                                        </label>
                                    </td>
                                    <td className="text-center">
                                        <label htmlFor="peri_rt_total">
                                            Total
                                        </label>
                                    </td>
                                    <td>
                                        <input
                                            name="peri_rt_total"
                                            id="peri_rt_total"
                                            type="text"
                                            value={this.state.peri_rt_total}
                                            onChange={(e) => this.onChangeHandler(e)}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <strong>
                                            <u>
                                                {translate("form_vision_perimeter_left_temporal")}
                                            </u>
                                        </strong>
                                    </td>
                                    <td className="text-center">
                                        <label
                                            className={this.state.peri_lt_85 ? 'active': ''}
                                        >
                                            <input
                                                name="peri_lt_85"
                                                type="checkbox"
                                                checked={this.state.peri_lt_85}
                                                onChange={(e) => this.onChangeHandler(e)}
                                            />
                                            85°
                                        </label> 
                                    </td>
                                    <td className="text-center">
                                        <label
                                            className={this.state.peri_lt_70 ? 'active': ''}
                                        >
                                            <input
                                                name="peri_lt_70"
                                                type="checkbox"
                                                checked={this.state.peri_lt_70}
                                                onChange={(e) => this.onChangeHandler(e)} 
                                            />
                                                70°
                                        </label> 
                                    </td>
                                    <td className="text-center">
                                        <label
                                            className={this.state.peri_lt_55 ? 'active': ''}
                                        >
                                            <input
                                                name="peri_lt_55"
                                                type="checkbox"
                                                checked={this.state.peri_lt_55}
                                                onChange={(e) => this.onChangeHandler(e)}
                                            />
                                            55°
                                        </label>
                                    </td>
                                    <td className="text-center">
                                        <label
                                            className={this.state.peri_lt_45 ? 'active': ''}
                                        >
                                            <input
                                                name="peri_lt_45"
                                                type="checkbox"
                                                checked={this.state.peri_lt_45}
                                                onChange={(e) => this.onChangeHandler(e)}
                                            />
                                            Nasal 45°
                                        </label>
                                    </td>
                                    <td className="text-center">
                                        <label htmlFor="peri_lt_total">
                                            Total
                                        </label>
                                    </td>
                                    <td>
                                        <input
                                            name="peri_lt_total"
                                            id="peri_lt_total"
                                            type="text"
                                            value={this.state.peri_lt_total}
                                            onChange={(e) => this.onChangeHandler(e)}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td>
                                        <label htmlFor="peri_both_total">Both Eyes</label>
                                    </td>
                                    <td className="text-center">
                                        <label htmlFor="peri_both_total">Total</label>
                                    </td>
                                    <td>
                                        <input
                                            name="peri_both_total"
                                            id="peri_both_total"
                                            type="text"
                                            value={this.state.peri_both_total}
                                            onChange={(e) => this.onChangeHandler(e)}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
				}
			</Translate>
		)
	}
}