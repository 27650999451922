let printer;
let isNative = false;
if (typeof (window.printer) !== "undefined") {
  isNative = true;
}

/*
function PDFPrint(data) {
	printer.printDirect({
		data: data,
		type: 'PDF',
		success: function(id) {
			console.log('printed with id ' + id);
		},
		error: function(err) {
			console.error('error on printing: ' + err);
		}
	})
}
*/


function PDFPrint(file, cb) {
	if(!isNative)return;
	const fs = window.native_fs;  // eslint-disable-line no-undef
	const os = window.native_os;  // eslint-disable-line no-undef
	let tmpfile = os.tmpdir()+'\\pe_temp.pdf';
	fs.writeFileSync(tmpfile, file, 'binary');

	// console.log(tmpfile);
	
	var printWindow = window.open('/management/printpdf');
	printWindow.file = tmpfile;

	/*
	printWindow.donePrint = () => {
		printWindow.close();
		cb(true, stickers);
	};

	printWindow.cancelPrint = () => {
		printWindow.close();
		cb(false);
	};
	*/
}

export default PDFPrint;