import React, { Component } from "react";

import Logout from '../../components/Logout';
import Loading from '../../components/Loading';

import nativePrint from '../../helpers/NativePrint';

import authFetch from '../../services/authFetch';
import { Translate } from "react-localize-redux";

class ManagementVisitSpecimen extends Component {
	constructor(props) {
    super(props);
    this.state = {
	    loading:true,
	    vn: this.props.match.params.vn,
	    all:false,
	    stickers: []
    };
    
    this.togglePrint = this.togglePrint.bind(this);
    this.printStickers = this.printStickers.bind(this);
    this.afterPrint = this.afterPrint.bind(this);
    this.goBack = this.goBack.bind(this);
    this.toggleAll = this.toggleAll.bind(this);
	this.editCode = this.editCode.bind(this)
	this.initList = this.initList.bind(this)
  }
    
  componentDidMount() {
	this.initList()
  }

  initList() {
	authFetch.get('visit/'+this.state.vn+'/all-stickers',(result)=>{
		//console.log(result)
		if(result.success){
			this.setState({loading:false,stickers:result.stickers});
	  }
	});

  }

  editCode(id) {
	window.doPrompt('enter_new_barcode',(text)=>{
		let data = {new_barcode:text}
		authFetch.post('visit/specimen/new-barcode/'+id,data,(result)=>{
			if(result.success){
				this.initList();
			} else {
				this.setState({loading:false});
				window.doAlert(result.errors[0])
				// window.doConfirm('error_change_barcode',this.goBack,()=>{});
			}
		})
	},()=>{});
  }
  
  togglePrint(id,check)
  {
	  var stickers = this.state.stickers;
	  var result = [];
	  stickers.map((item,index)=>{
		  if(item.id===id)
		  {
			  item.print=check;
		  }
		  result.push( item );
	  });
	  this.setState({stickers:result})
  }
  
  toggleAll(check)
  {
	  var stickers = this.state.stickers;
	  var result = [];
	  stickers.map((item,index)=>{
		  item.print=check;
		  result.push( item );
	  });
	  this.setState({all:check,stickers:result})
  }
  
  printStickers()
	{
		var stickers = [];
		this.state.stickers.map((item,index)=>{
			if(item.print)stickers.push( item );
		});
	  if(stickers.length>0)
	  {
			nativePrint(stickers,this.afterPrint);
		}
	}
	
	afterPrint(success,stickers)
	{
		if(success)
		{
			var prints = [];
			var specimens = stickers.map((item,index)=>{
				if(item.print)prints.push( item.id );
			});
		  var data = {specimens:prints}
		  authFetch.put('visit/stickers',data,(result)=>{
			  if(result.success){
				  this.goBack();
		    } else {
			  	this.setState({loading:false});
				  window.doConfirm('error_save_print_status_exit',this.goBack,()=>{});
		    }	
		  });
		} else {
		  this.setState({loading:false});
		  window.doConfirm('saved_error_save_print_status_exit', this.goBack, () => { });
		  window.doConfirm('error_printing_exit',this.goBack,()=>{});
		}
  }
	
	goBack()
	{
		var backTo = '/management/patient/'+this.state.vn;
		if(this.props.location.state && this.props.location.state.backTo) 
		{
			backTo = this.props.location.state.backTo;
		} 
		this.props.history.push(backTo);
	}

  render() {
	  
    return (
			<Translate>
			{({ translate }) => 
	      <div className="management-page page">
	      	<header className="page-header">
		      	<button className="link-page-back"  onClick={()=>this.goBack()}>
	      			{translate("back")}
	      		</button>
		      	<h1>{translate("print_sticker")}</h1>
		      	<Logout/>
	      	</header>
	      	
	      	<main className="page-content">
		      	<div className="page-content-main">
		      		{(this.state.stickers.length>0)?
			      		(
				      		<div>
						      	<h5>VN: {this.state.stickers[0].vn} {this.state.stickers[0].fullname}</h5>
					      		<table className="light-table">
								      	<thead>
									      	<tr>
										      	<th>{translate("change")}</th>
										      	<th>{translate("barcode_number")}</th>
										      	<th>{translate("specimen")}</th>
										      	<th className="text-center" colSpan="2">{translate("print")}</th>
									      	</tr>
								      	</thead>
								      	<tbody>
									      	{this.state.stickers.map((item,index)=>{
										      	return (
											      	<tr key={index}>
													  	<td className="text-center">
															<button type="button" className="btn btn-round-sm" onClick={()=>this.editCode(item.id)}>{translate("change")}</button>
														</td>
												      	<td className="text-center">{item.id}</td>
												      	<td className="text-center">{item.name}</td>
												      	
												      	<td className="text-center"  width="70">
													      	{item.print?(
									      					<button className="mini-check" onClick={()=>this.togglePrint(item.id, false)}>
										      					<i className="fa fa-check"></i>
										      				</button>
										      				):(
									      					<button className="mini-check" onClick={()=>this.togglePrint(item.id, true)}>
										      					<i></i>
										      				</button>
										      				)}
												      	</td>
											      	</tr>
										      	)
									      	})}
									      	
									      	<tr>
										      	<td className="text-right" colSpan="3">{translate("select_all")}</td>
										      	
										      	<td className="text-center" width="70">
											      	{this.state.all?(
							      					<button className="mini-check" onClick={()=>this.toggleAll(false)}>
								      					<i className="fa fa-check"></i>
								      				</button>
								      				):(
							      					<button className="mini-check" onClick={()=>this.toggleAll(true)}>
								      					<i></i>
								      				</button>
								      				)}
										      	</td>
									      	</tr>
								      	</tbody>
							      	</table>
							      	<br/>
							      	<p className="text-right">
								      	<button type="button" className="btn btn-round" onClick={this.printStickers}>
									      	{translate('print_sticker')} <i className="fa fa-print"></i>
									      </button>
								      </p>
					      	</div>
			      		) : (null)
							}
	      		</div>
						<Loading show={this.state.loading} />
		      </main>
	      </div>
      }
			</Translate>
    );
  }
}

export default ManagementVisitSpecimen;
