import React, { Component } from "react";

import Logout from '../../components/Logout';
import Loading from '../../components/Loading';
import Auth from '../../helpers/Auth';
import authFetch from '../../services/authFetch';
import { Translate } from "react-localize-redux";

class ManagementPEForm extends Component {
	constructor(props) {
		super(props);

		this.state = {
			keyword: this.props.match.params.vn,
			visit: null,
			show_modal: false,
			loading: true,
			pe_list: [],
			forigns_list: [],
			personal_list: [],
			practitioners: [],
			practitioner_id: null,
			is_doctor: false,
			pe: {
				wt: "",
				ht: "",
			},
			note: "",
			pes: [],
			bp: [{ sbp: "", dbp: "", pulse: "" }],
			templates: {},
			history_taking_by_id: null
		};

		this.addBp = this.addBp.bind(this);
		this.removeBp = this.removeBp.bind(this);
		this.doSubmit = this.doSubmit.bind(this);
		this.handlePeChange = this.handlePeChange.bind(this);
		this.handleBpChange = this.handleBpChange.bind(this);

		this.handlePesChange = this.handlePesChange.bind(this);
		this.handlePesNoteChange = this.handlePesNoteChange.bind(this);
		this.handlePersonalsChange = this.handlePersonalsChange.bind(this);
		this.handlePersonalsNoteChange = this.handlePersonalsNoteChange.bind(this);
		this.handlePractitionerChange = this.handlePractitionerChange.bind(this);
		this.handleHistoryTakenChange = this.handleHistoryTakenChange.bind(this);
		this.startExamined = this.startExamined.bind(this);
		
		
		this.afterSave = this.afterSave.bind(this);
		this.loadVisit = this.loadVisit.bind(this);
		this.goBack = this.goBack.bind(this);
		this.ctrlSubmit = this.ctrlSubmit.bind(this);
	}

	componentWillUnmount() {
		document.removeEventListener("keydown", this.ctrlSubmit, false);
	}

	ctrlSubmit(e) {
		if (e.ctrlKey && e.keyCode == 13) {
			// Ctrl-Enter pressed
			this.doSubmit(e);
		}
		return;
	}

	componentDidMount() {
		document.addEventListener("keydown", this.ctrlSubmit, false);
		// authFetch.get('pe', (result) => {
		// 	//console.log(result)
		// 	if (result.success) {
		// 		this.loadVisit(result.pe_templates, result.personal_templates, result.practitioners);
		// 	}
		// });
		this.loadVisit();
	}

	loadVisit() {
		authFetch.get('bp/search?keyword=' + this.state.keyword, (result) => {
			if (result.success && result.visit) {
				let pe_templates = result.pe_templates;
				let personal_templates = result.personal_templates;
				let practitioners = result.practitioners;
				let med_cert_foreigns = result.med_cert_foreign;
				let history_taking_by = result.history_taking_by;
				this.setState({
					templates: {
						pe_templates: pe_templates,
						personal_templates: personal_templates,
						practitioners: practitioners,
						med_cert_foreigns: med_cert_foreigns,
						history_taking_by: history_taking_by
					}
				})
				//console.log(result)
				let pes = [];
				if(pe_templates){
					pe_templates.map((item, index) => {
						result.visit.pe.map((vitem, vindex) => {
							if (item.id === vitem.id) {
								item.value = vitem.value;
								item.note = vitem.note;
								if (item.value) {
									item.choices.map((choice) => {
										if (item.value === choice.value) {
											item.show_text_input = choice.has_text_input
										}
									});
								}
							}
						});

						if (item.value === undefined) {
							item.choices.map((choice) => {
								if (choice.is_default) {
									item.value = choice.value
									item.show_text_input = choice.has_text_input
								}
							});
						}
						if (item.value === undefined) {
							item.value = null;//"1";
							item.show_text_input = 0;
						}
						if (!item.note) {
							item.note = "";
						}
						pes.push(item)
					});
				}

				let forigns = [];
				if(med_cert_foreigns){
					med_cert_foreigns.map((item, index) => {
						if(result.visit.med_cert_foreign){
							result.visit.med_cert_foreign.map((vitem, vindex) => {
								if (item.id === vitem.id) {
									item.value = vitem.value;
									item.note = vitem.note;
									if (item.value) {
										item.choices.map((choice) => {
											if (item.value === choice.value) {
												item.show_text_input = choice.has_text_input
											}
										});
									}
								}
							});
						}

						if (item.value === undefined) {
							item.choices.map((choice) => {
								if (choice.is_default) {
									item.value = choice.value
									item.show_text_input = choice.has_text_input
								}
							});
						}
						if (item.value === undefined) {
							item.value = null;//"1";
							item.show_text_input = 0;
						}
						if (!item.note) {
							item.note = "";
						}
						forigns.push(item)
					});
				}

				var wt = null;
				var ht = null;
				var temp = null;
				var is_doctor = false;
				var personals = [];
				personal_templates.map((item, index) => {
					result.visit.personal.map((vitem, vindex) => {
						if (item.id === vitem.id) {
							item.value = vitem.value;
							item.note = vitem.note;

							if (item.value) {
								item.choices.map((choice) => {
									if (item.value === choice.value) {
										item.show_text_input = choice.has_text_input
									}
								});
							}

							if (item.handle === 'wt') wt = vitem.value;
							if (item.handle === 'ht') ht = vitem.value;
							if (item.handle === 'temp') temp = vitem.value;
						}
					});
					// console.log(item)
					if (item.value === null || item.value === undefined) {
						item.choices.map((choice) => {
							// console.log(choice)
							if (choice.is_default) {
								item.value = choice.value
								item.show_text_input = choice.has_text_input
							}
						});
					}
					if (item.value === null || item.value === undefined) {
						item.value = null;//"1";
						item.show_text_input = 0;
					}
					if(!item.note) {
						item.note="";
					}
					// console.log(item)
					personals.push(item)
				});
				// console.log(personals)

				let practitioner_id = result.visit.practitioner_id;
				// console.log(Auth)
				if (!practitioner_id) {
					if (Auth.user_role === 'doctor') {
						practitioner_id = Auth.user_id;
						is_doctor = true;
					}
				}

				var set = {
					pe: {
						wt: wt,
						ht: ht,
						temp: temp
					},
					is_examined: result.visit.is_examined,
					bp: result.visit.bp,
					visit: result.visit,
					pe_list: pes,
					forigns_list: forigns,
					practitioners: practitioners,
					personal_list: personals,
					practitioner_id: practitioner_id,
					is_doctor: is_doctor
				}
				if (set.bp.length === 0) set.bp = [{ sbp: "", dbp: "", pulse: "" }];
				// console.log(set)
				this.setState(set);
				setTimeout(()=>{
					let elements = document.getElementsByClassName('autosize');
					for(let i=0;i<elements.length;i++) {
						this.changeHeightTextarea(elements[i])
					}
				},300)
			} else {
				this.setState({ visit: [] });
			}
			this.setState({ loading: false });
		});
	}

	handleHistoryTakenChange(event) {
		const target = event.target;
		const value = (target.type === 'checkbox' || target.type === 'radio') ? target.value : target.value;
		// this.setState({ history_taking_by_id: value });
		this.setState({ visit: { ...this.state.visit, ...{ history_taking_by_id: value } } });
	}

	handlePractitionerChange(event) {
		const target = event.target;
		const value = (target.type === 'checkbox' || target.type === 'radio') ? target.value : target.value;
		this.setState({ practitioner_id: value });
	}

	handlePeChange(event) {
		const target = event.target;
		const value = (target.type === 'checkbox' || target.type === 'radio') ? target.value : target.value;
		const name = target.name;
		this.setState({ pe: { ...this.state.pe, ...{ [name]: value } } });
	}

	handleBpChange(index, event) {
		const target = event.target;
		const value = (target.type === 'checkbox' || target.type === 'radio') ? target.value : target.value;
		const name = target.name;
		var bps = this.state.bp;
		bps[index] = { ...bps[index], ...{ [name]: value } }
		this.setState({ bp: bps });
	}

	handlePesChange(selected, choice) {
		let pes = [];
		this.state.pe_list.map((item, index) => {
			if (item.id === selected.id) {
				if(item.value===choice.value) {
					item.value=null;
				} else {
					item.value=choice.value;
					item.show_text_input=choice.has_text_input;
				}
			}
			pes.push(item);
		});
		this.setState({ pe_list: pes });
	}

	handleForignsChange(selected, choice) {
		let forigns = [];
		this.state.forigns_list.map((item, index) => {
			if (item.id === selected.id) {
				if(item.value===choice.value) {
					item.value=null;
				} else {
					item.value=choice.value;
					item.show_text_input=choice.has_text_input;
				}
			}
			forigns.push(item);
		});
		this.setState({ forigns_list: forigns });
	}

	handlePesNoteChange(selected, event) {
		let pes = [];
		this.state.pe_list.map((item, index) => {
			if (item.id === selected.id) {
				item.note = event.target.value;
				this.changeHeightTextarea(event.target)
			}
			pes.push(item);
		});
		this.setState({ pe_list: pes  });
	}

	changeHeightTextarea(target) {
		target.style.height = 'auto';
		target.style.height = (target.scrollHeight+5)+'px'
	}

	handleForignsNoteChange(selected, event) {
		let forigns = [];
		this.state.forigns_list.map((item, index) => {
			if (item.id === selected.id) {
				item.note = event.target.value;
				this.changeHeightTextarea(event.target)
			}
			forigns.push(item);
		});
		this.setState({  forigns_list: forigns  });
	}

	handlePersonalsChange(selected, choice) {
		var pers = [];
		this.state.personal_list.map((item, index) => {
			if (item.id === selected.id) {

				if(item.value===choice.value) {
					item.value=null;
				} else {
					item.value=choice.value;
					item.show_text_input=choice.has_text_input;
				}

				// item.value = value;
			}
			pers.push(item);
		});
		this.setState({ personal_list: pers });
	}

	handlePersonalsNoteChange(selected, event) {
		var pers = [];
		this.state.personal_list.map((item, index) => {
			if (item.id === selected.id) {
				item.note = event.target.value;
				this.changeHeightTextarea(event.target)
			}
			pers.push(item);
		});
		this.setState({ personal_list: pers });
	}

	addBp() {
		var bps = this.state.bp;
		bps.push({ sbp: "", dbp: "", pulse: "" });
		this.setState({ bp: bps });
	}

	removeBp(index) {
		var bps = this.state.bp;
		bps.splice(index, 1);
		this.setState({ bp: bps });
	}

	startExamined() {
		this.setState({ is_examined: true });
	}

	resetExamined() {
		window.doConfirm(
			"confirm_reset_pe",
			() => { 
				let pes = [];
				if(this.state.templates.pe_templates) {
					this.state.templates.pe_templates.map((item, index) => {
						item.value = null;//"1";
						item.show_text_input = 0;
						item.note = "";
						item.choices.map((choice) => {
							if (choice.is_default) {
								item.value = choice.value
								item.show_text_input = choice.has_text_input
							}
						});
						pes.push(item)
					});
				}

				let forigns = [];
				if(this.state.templates.med_cert_foreigns){
					this.state.templates.med_cert_foreigns.map((item, index) => {
						item.value = null;//"1";
						item.show_text_input = 0;
						item.note = "";
						item.choices.map((choice) => {
							if (choice.is_default) {
								item.value = choice.value
								item.show_text_input = choice.has_text_input
							}
						});
						forigns.push(item)
					});
				}

				this.setState({ 
					pe_list: pes,
					forigns_list: forigns,
					is_examined: false
				}); 
			},
			() => { }
		)
	}

	calcBmi (wt,ht) {
        let bmi_meaning = '';
        let bmi = Math.floor(wt / (ht / 100 * ht / 100));
        if (bmi < 18.5) {
            bmi_meaning = "underweight"
        } else if (bmi < 23) {
            bmi_meaning = "bmi_normal"
        } else if (bmi < 25) {
            bmi_meaning = "overweight"
        } else {
            bmi_meaning = "obesity"
        }
        return bmi;// +" "+ bmi_meaning
    }

	doSubmit(e, action) {
		e.preventDefault();
		this.setState({ loading: true });
		var pes = [];
		this.state.pe_list.map((item, index) => {
			pes.push({ id: item.id, value: item.value, note: item.note })
		});

		var forigns = [];
		this.state.forigns_list.map((item, index) => {
			forigns.push({ id: item.id, value: item.value, note: item.note })
		});

		var pers = [];
		this.state.personal_list.map((item, index) => {
			if (item.handle === 'wt') pers.push({ id: item.id, value: this.state.pe.wt, note: null });
			else if (item.handle === 'ht') pers.push({ id: item.id, value: this.state.pe.ht, note: null });
			else if (item.handle === 'temp') pers.push({ id: item.id, value: this.state.pe.temp, note: null });
			else pers.push({ id: item.id, value: item.value, note: item.note });
		});
		var data = {
			vn: this.state.visit.vn,
			bp: this.state.bp,
			pe: pes,
			med_cert_foreign: forigns,
			history_taking_by_id: this.state.visit.history_taking_by_id,
			is_examined: this.state.is_examined,
			personal: pers,
			practitioner_id: this.state.practitioner_id
		}
		//console.log(data);
		//console.log(JSON.stringify(data))
		authFetch.post('pe', data, (result) => {
			this.afterSave(result, action)
		});
		return false;
	}

	goBack() {
		var backTo = '/management/pe_search';
		if (this.props.location.state && this.props.location.state.backTo) {
			backTo = this.props.location.state.backTo;
		}
		this.props.history.push(backTo);
	}

	afterSave(result, action) {
		if (result.success) {
			if (action === 'print') {
				this.props.history.push('/management/mc_print/' + this.state.visit.vn, { backTo: '/management/pe_search' })
			} else if (action === 'print_pe') {
				this.props.history.push('/management/pe_print/' + this.state.visit.vn, { backTo: '/management/pe_search' })
			} else {
				this.goBack();
			}
		} else {
			this.setState({ loading: false });
			if (result.errors) window.doAlert(result.errors.join('\n'))
			else {
				// console.log(result);
				window.doAlert("Server Response Error");
			}
		}
	}

	render() {
		return (
			<Translate>
				{({ translate }) =>
					<div className="management-page page">
						<header className="page-header">
							<button className="link-page-back" onClick={this.goBack}>
								{translate("back")}
							</button>
							<h1>{translate("save_pe_result")}</h1>
							<Logout />
						</header>

						<main className="page-content">
							{this.state.visit ? (
								<div className="pe-form-wrapper">
									<div className="pe-form-body">
										<div className="pe-form-left">
											<h6 className="text-left">{translate("weight")} - {translate("height")}</h6>
											<div className="pe-fields">
												<div className="pe-field-row">
													<div className="pe-field-group">
														<label>{translate("weight")}</label>
														<input className="pe-field-input" type="text" name="wt"
															value={this.state.pe.wt ? this.state.pe.wt : ""} onChange={this.handlePeChange} autoFocus />
														<label>{translate("kg")}</label>
													</div>
													<div className="pe-field-group">
														<label>{translate("height")}</label>
														<input className="pe-field-input" type="text" name="ht"
															value={this.state.pe.ht ? this.state.pe.ht : ""} onChange={this.handlePeChange} />
														<label>{translate("cm")}</label>
													</div>
												</div>
												<div className="pe-field-row">
													<div className="pe-field-group">
														<label>{translate("temperature")}</label>
														<input className="pe-field-input" type="text" name="temp"
															value={this.state.pe.temp ? this.state.pe.temp : ""} onChange={this.handlePeChange} />
														<label>°</label>
													</div>
													<div className="pe-field-group">
														<label>BMI: </label>
														{ (this.state.pe.wt && this.state.pe.ht)?
															<strong>&nbsp;{this.calcBmi(this.state.pe.wt,this.state.pe.ht)}</strong>
														:null}
													</div>
												</div>
											</div>

											<h6 className="text-left">{translate("bp")} - {translate("pulse")}</h6>
											<div className="pe-fields">
												{this.state.bp.map((item, index) => {
													return (
														<div className="pe-field-row" key={index}>
															<div className="pe-field-group">
																<label>{translate("bp")}</label>
																<input className="pe-field-input" type="text" name="sbp"
																	value={item.sbp} onChange={(event) => this.handleBpChange(index, event)} />
																/
																<input className="pe-field-input" type="text" name="dbp"
																	value={item.dbp} onChange={(event) => this.handleBpChange(index, event)} />
															</div>
															<div className="pe-field-group">
																<label>{translate("pulse")}</label>
																<input className="pe-field-input" type="text" name="pulse"
																	value={item.pulse} onChange={(event) => this.handleBpChange(index, event)} />
															</div>
															<div className="pe-field-row-icon">
																{index === 0 ? (null) : (
																	<i onClick={() => this.removeBp(index)} className="fa fa-trash"></i>
																)}
															</div>
														</div>
													)
												})}
											</div>
											<p className="text-center">
												<button type="button" onClick={this.addBp} className="btn btn-round"><i className="fa fa-plus-circle"></i> Add BP/Pulse</button>
											</p>

											<br />
											<div className="pe-list">
												{this.state.personal_list.map((item, index) => {
													if (item.handle == 'wt' || item.handle == 'ht' || item.handle == 'temp') return null;
													return (
														<div className="pe-list-item" key={index}>
															<div className="pe-list-item-title">{item.name}:</div>
															{item.choices === null || item.choices.length === 0 ? (
																<div className="pe-list-item-comment">
																	<textarea rows="1" className="pe-note autosize" onChange={(event) => this.handlePersonalsNoteChange(item, event)} value={item.note}></textarea>
																</div>
															) : (
																<div className="pe-list-item-content">
																	<div className="pe-list-item-select">
																		{item.choices.map((choice, pindex) => {
																			return <button className="pe-list-item-radio" key={pindex} onClick={() => this.handlePersonalsChange(item, choice)}>
																				<i className={"far fa-" + ((item.value === choice.value) ? 'check-circle' : 'circle')}></i>
																				<strong><small> {choice.title}</small></strong>
																			</button>
																		})}
																	</div>
																	<div className="pe-list-item-note">
																		{item.show_text_input ? (
																			<textarea rows="1" className="pe-note autosize" onChange={(event) => this.handlePersonalsNoteChange(item, event)} value={item.note} />
																		) : (null)}
																	</div>
																</div>
															)}
															
														</div>
													)
												})}

												{/*this.state.personal_list.map((item, index) => {
													if (item.handle !== 'fasting') return null;
													return (
														<div className="pe-list-item" key={index}>
															<div className="pe-list-item-title">{item.name}:</div>

															
															<div className="pe-list-item-content">
																<div className="pe-list-item-select">
																	<button className="pe-list-item-radio" onClick={() => this.handlePersonalsChange(item.id, "0")}>
																		<i className={"far fa-" + ((item.value === "0") ? 'check-circle' : 'circle')}></i>
																		<strong> งด</strong>
																	</button>
																	<button className="pe-list-item-radio" onClick={() => this.handlePersonalsChange(item.id, "1")}>
																		<i className={"far fa-" + ((item.value === "1") ? 'check-circle' : 'circle')}></i>
																		<strong> ไม่งด</strong>
																	</button>
																</div>
																<div className="pe-list-item-note">
																	{item.value === "1" ? (
																	<textarea rows="1" className="pe-note autosize" onChange={(event) => this.handlePersonalsNoteChange(item.id, event)} value={item.note} placeholder="ทานล่าสุดเมื่อ" />
																	) : (null)}
																</div>
															</div>
														</div>
													)
												})*/}
											</div>

											<div className="pe-fields">
												<div className="pe-field-row">
													{this.state.visit && this.state.templates&&this.state.templates.history_taking_by ? (
														<div className="pe-list-item">
															<div className="pe-list-item-title">ผู้ซักประวัติ</div>
															<div className="pe-list-item-content">
																<select className="form-control" onChange={this.handleHistoryTakenChange} disabled
																	value={this.state.visit.history_taking_by_id ? this.state.visit.history_taking_by_id : ""} >
																	<option value="">{translate("not_specific")}</option>
																	{this.state.templates.history_taking_by.map((item, index) => {
																		return (<option value={item.id} key={index}>{item.fullname}</option>)
																	})}
																</select>
															</div>
														</div>
													) : null}
												</div>
											</div>
										</div>
										{this.state.visit.pe_order_id !== null?
										<div className="pe-form-right">
											{this.state.is_examined?
												<div>
													<button className="btn-round btn-round-sm pe-reset-btn" onClick={(event) => this.resetExamined()}>{translate("reset_examined")}</button>
													<h6 className="text-left">{translate("basic_assessment")}</h6>
													<div className="pe-list">
														{this.state.pe_list.map((item, index) => {
															return (
																<div className="pe-list-item" key={index}>
																	<div className="pe-list-item-title">{item.name}:</div>
																	{item.choices === null || item.choices.length === 0 ? (
																		<div className="pe-list-item-comment">
																			<textarea rows="1" className="pe-note autosize" onChange={(event) => this.handlePesNoteChange(item, event)} value={item.note}></textarea>
																		</div>
																	) : (
																		<div className="pe-list-item-content">
																			<div className="pe-list-item-select">
																				{item.choices.map((choice, pindex) => {
																					return <button className="pe-list-item-radio" key={pindex} onClick={() => this.handlePesChange(item, choice)}>
																						<i className={"far fa-" + ((item.value === choice.value) ? 'check-circle' : 'circle')}></i>
																						<strong><small> {choice.title}</small></strong>
																					</button>
																				})}
																			</div>
																			<div className="pe-list-item-note">
																				{item.show_text_input ? (
																					<textarea rows="1" className="pe-note autosize" onChange={(event) => this.handlePesNoteChange(item, event)} value={item.note} />
																				) : (null)}
																			</div>
																		</div>
																	)}
																</div>
															)
														})}
													</div>
													{this.state.forigns_list && this.state.forigns_list.length > 0?
													<div className="pe-list">
														{this.state.forigns_list.map((item, index) => {
															return (
																<div className="pe-list-item" key={index}>
																	<div className="pe-list-item-title">{item.name}:</div>
																	{item.choices === null || item.choices.length === 0 ? (
																		<div className="pe-list-item-comment">
																			<textarea rows="1" className="pe-note autosize" onChange={(event) => this.handleForignsNoteChange(item, event)} value={item.note} />
																		</div>
																	) : (
																		<div className="pe-list-item-content">
																			<div className="pe-list-item-select">
																				{item.choices.map((choice, pindex) => {
																					return <button className="pe-list-item-radio" key={pindex} onClick={() => this.handleForignsChange(item, choice)}>
																						<i className={"far fa-" + ((item.value === choice.value) ? 'check-circle' : 'circle')}></i>
																						<strong><small> {choice.title}</small></strong>
																					</button>
																				})}
																			</div>
																			<div className="pe-list-item-note">
																				{item.show_text_input ? (
																					<textarea rows="1" className="pe-note autosize" onChange={(event) => this.handleForignsNoteChange(item, event)} value={item.note} />
																				) : (null)}
																			</div>
																		</div>
																	)}
																</div>
															)
														})}
													</div>
													:null}
													{this.state.visit.pe_order_id !== null?
													<div className="pe-fields">
														<div className="pe-field-row">
															{this.state.practitioners ? (
																<div className="pe-list-item">
																	<div className="pe-list-item-title">{translate("select_doctor")} </div>
																	<div className="pe-list-item-content">
																		<select className="form-control" onChange={this.handlePractitionerChange}
																			value={this.state.practitioner_id ? this.state.practitioner_id : ""}>
																			<option value="">{translate("not_specific")}</option>
																			{this.state.practitioners.map((item, index) => {
																				return (<option value={item.id} key={index}>{item.fullname} ({item.lid??'-'})</option>)
																			})}
																		</select>
																	</div>
																</div>
															) : null}
														</div>
													</div>
													:null}
												</div>
											:
												<div>
													<h6 className="text-left">{translate("basic_assessment")}</h6>
													<div className="text-center">
														<br/>
														<button className="btn-round" onClick={(event) => this.startExamined()}>
															{translate("click_to_start_examine")}
														</button>
													</div>
												</div>
											}
										</div>
										:
										<div className="pe-form-right">
											<div className="text-center">ไม่มีการตรวจ PE</div>
										</div>
										}
									</div>
									<div className="pe-form-footer">
										<div className="pe-form-footer-info">
											<p><strong>{translate("name")}</strong>: {this.state.visit.fullname} ({this.state.visit.age} ปี)</p>
											<p><strong>{translate("vn")}</strong>: {this.state.visit.vn}</p>
										</div>

										<div className="pe-form-footer-button">
											<button className="btn-round btn-block" onClick={(event) => this.doSubmit(event, 'print')}>{translate("save_and_print_data")}</button>
										</div>
										&nbsp;
										<div className="pe-form-footer-button">
											<button className="btn-round btn-block" onClick={(event) => this.doSubmit(event, 'print_pe')}>{translate("save_and_print_pe")}</button>
										</div>
										&nbsp;
										<div className="pe-form-footer-button">
											<button className="btn-round btn-block" onClick={(event) => this.doSubmit(event)}>{translate("save_data")}</button>
										</div>
									</div>
								</div>
							) : (null)}
							<Loading show={this.state.loading} />
						</main>
					</div>
				}
			</Translate>
		);
	}
}

export default ManagementPEForm;
