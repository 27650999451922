import React, { Component } from "react";
import { Translate } from "react-localize-redux";
import Loading from './Loading';
import MiniLoading from './MiniLoading';
import moment from 'moment';
import MRZParser from '../helpers/MRZParser.js';
import authFetch from '../services/authFetch';
var passport_reader = null;
class CardReader extends Component {
	constructor(props) {
		super(props);

		this.state = {
			show_modal: false,
			has_reader: false,
			reading: false,
			loading: false,
      nationalities: []
		};

		this.updateData = this.updateData.bind(this);
    this.initPassportReader = this.initPassportReader.bind(this);
    this.processPassportReader = this.processPassportReader.bind(this);
    
		this.showModel = this.showModel.bind(this);
		this.hideModel = this.hideModel.bind(this);
	}

	componentDidMount() {
    this.setState({ 'has_reader': window.hasPassportReader });
		authFetch.get('nationalities', (result) => {
			//console.log(result)
			if (result.success) {
				this.setState({ nationalities: result.nationalities });
			}
		});
	}

	componentWillUnmount() {
    if(passport_reader)passport_reader.kill();
  }

	showModel() {
		this.setState({ 'show_modal': true });
		this.readCard();
	}

	hideModel() {
		this.setState({ 'show_modal': false });
	}

	updateData(data) {
		this.props.update(data);
		this.hideModel();
	}

  initPassportReader() {
		this.setState({ 'reading': true });
    passport_reader = window.cp.spawn( window.readerExe, ['{"device-name":"A62","source":"Camera","recognize-type":"passport"}'] );
    passport_reader.stdout.on( 'data', ( data ) => this.processPassportReader(data) );
    passport_reader.stderr.on( 'data', ( data ) => console.log( `stderr: ${ data }` ));
    passport_reader.on( 'close', ( code ) => {
      passport_reader = null;
      this.setState({ 'reading': false });
    });
  }

  processPassportReader(data) {
    // console.log(data)
    let dec = new TextDecoder("utf-8");
    if(!dec)return;
    let res = dec.decode(data).split('\n')
    var result = null;
    for(var i=0;i<res.length;i++) {
      // console.log(res[i])
      if(res[i].startsWith("Result:[JSON]")) {
        result = res[i+1];
        break;
      }
    }
    // Buffer.from(data).toString('utf-8')
    if(result) {
      try {
        result = JSON.parse(result)
      } catch (error) {
        result = null;
      }
    }

    if(result===null) return null;

    /*
    Angle:"0"
    Birthday:"911219"
    Checksum:true
    Dateofexpiry:"210404"
    DocumentNo:"AA6172909"
    Familyname:"RATTANASOMRERK"
    Givenname:"SAENDEE"
    IssueState:"THA"
    MRTDs:"P<THARATTANASOMRERK<<SAENDEE<<<<<<<<<<<<<<<<AA61729098THA9112193F21040491100800746675<14"
    Nationality:"THA"
    NativeName:""
    PersonalNo:"1100800746675"
    Sex:"F"
    Type:"P"
    */
    let parse = {}
    try {
      parse = MRZParser.parse(result['MRTDs']);
    } catch (e) {
      console.log(result['MRTDs'])
      console.log(e)
    }
    // console.log(parse)

    let map = {};
    // console.log( result )
    if(parse.sex){
      switch (parse.sex.abbr) {
        case "M":
          map.gender = "male";
          break;
        case "F":
          map.gender = "female";
          break;
        default:
          map.gender = "unknown";
          break;
      }
    }
    
    if(parse.dob){
      map.dob = `${parse.dob.year}-${parse.dob.month}-${parse.dob.day}`
    }

    if(parse.names){
      if(!parse.names.firstName) {
        map.firstname = parse.names.lastName
        map.lastname = '';
      } else {
        map.firstname = parse.names.firstName;
        map.lastname = parse.names.lastName;
      }
      map.fullname = `${map.firstname} ${map.lastname}`.trim()
    }
    // console.log(parse.nationality)
    if(parse.nationality){
      map.nationality = parse.nationality.abbr;
      this.state.nationalities.map((n)=>{
        if(n.code && parse.nationality.abbr && parse.nationality.abbr.startsWith(n.code)) {
          map.nationality_id = n.id
        }
      })
    }
    map.cid = parse.personalNumber;
    map.doc_no = parse.documentNumber;
    map.address = '';
    map.prefix = '';
    // console.log(map)
    this.updateData(map)
  }

	render() {
		return (
			<Translate>
				{({ translate }) =>
					<div className="card-reader" style={{"padding": "10px 0"}}>
            {this.state.reading?
              <div>
                กำลังอ่านพาสปอร์ต... <i className="fas fa-passport"></i> <div className="display-inline">
                <MiniLoading width="30" height="30" show="1"/></div>
              </div>
            :<div>
              {this.state.has_reader?
                <p>
                  {!this.props.hidetext?
                    <span>กรุณาวางพาสปอร์ตลงในเครื่องอ่าน <i className="fas fa-passport"></i></span>
                  :null}
                  &nbsp;&nbsp;
                    <button className="btn btn-round-sm" onClick={this.initPassportReader}>อ่าน Passport</button>
                </p>
              : null }
            </div>
            
            }
					</div>
				}
			</Translate>
		);
	}
}

export default CardReader;